/** @jsxImportSource @emotion/react */
import React from 'react'
import { Box } from '@mui/material'
import { styles } from './styles'

const LoadPage = () => {
    return (
        <Box component="div" css={styles.wrapper}>
            <div css={styles.logoWrapper}>
                <img src="/logo.svg" alt={'SCAP_LOGO'} />
                <img src="/flight.svg" css={styles.airImage} alt={'SCAP_AIRPLANE'} />
            </div>
        </Box>
    )
}

export default LoadPage
