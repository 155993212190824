import React from 'react'

import { useTheme } from '@mui/material'
import { css } from '@emotion/react'

export default (error: boolean, empty: boolean) => {
    const theme = useTheme()

    return React.useMemo(
        () => ({
            helperText: css`
                display: ${error ? 'block' : 'none'};
                color: ${theme.palette.error.main};
            `,
            input: css`
                & .MuiInputBase-input {
                    color: ${error ? theme.palette.error.main : theme.palette.info.main};
                }
            `,
            label: css`
                color: ${error || empty ? theme.palette.error.light : theme.palette.info.main};
                max-width: calc(100% - 17px);
            `,
        }),
        [theme, error, empty],
    )
}
