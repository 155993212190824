import React from 'react'

import { css } from '@emotion/react'

export const useStyles = () => {
    return React.useMemo(
        () => ({
            message: css`
                white-space: pre-wrap;
            `,
        }),
        [],
    )
}
