import React from 'react'
import { modalCtx } from '../../context/modal/modalContext'
import { ModalCtxType, ModalTypes } from '../../context/modal/types'
import AircraftMalfunctionsModal from './AircrafMalfunctionsModal/AircraftMalfunctionsModal'
import AlertModal from './Alert/Alert'
import AlertTowLimited from './Alert/AlertTowLimited'

const getModal = (modalData: ModalCtxType) => {
    switch (modalData.type) {
        case ModalTypes.MALFUNCTIONS_MODAL:
            return <AircraftMalfunctionsModal />
        case ModalTypes.ALERT_MODAL:
            return (
                <AlertModal message={modalData.payload?.message as string} title={modalData.payload?.title as string} />
            )
        case ModalTypes.ALERT_TOW_LIMITED_MODAL:
            if (modalData.payload !== undefined && 'mtow' in modalData.payload) {
                return (
                    <AlertTowLimited
                        message={modalData.payload.message}
                        title={modalData.payload.title}
                        mtow={modalData.payload.mtow}
                    />
                )
            } else {
                return <></>
            }
        default:
            return <></>
    }
}

const Modal = () => {
    const modal = React.useContext(modalCtx)
    const modalComponent = getModal(modal)
    return <>{modalComponent}</>
}

export default React.memo(Modal)
