import React, { useContext } from 'react'

import fetcher from '../api/fetcher'
import { userSetterCtx } from '../context/user/userContext'
import { formSetterCtx, initialFormCtxData } from '../context/form/formContext'

export default () => {
    const userSetter = React.useContext(userSetterCtx)
    const formSetter = useContext(formSetterCtx)

    const logOut = React.useCallback(() => {
        userSetter(null)
        formSetter(initialFormCtxData)
    }, [userSetter, formSetter])

    return React.useCallback(
        async <T>(input: RequestInfo, init: RequestInit, showError = true) => {
            return await fetcher<T>({
                input,
                init,
                showError,
                logOut,
            })
        },
        [logOut],
    )
}
