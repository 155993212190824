/** @jsxImportSource @emotion/react */
import React from 'react'
import { FormControl, InputLabel } from '@mui/material'

import MySelect from '../../../ui/components/MySelect/MySelect'
import { formCtx, formSetterCtx } from '../../../../context/form/formContext'
import useStyles from './useStyles'
import { runwayInfoSetterCtx } from '../../../../context/runwayInfo/runwayInfoContext'
import { runwayOptionsCtx } from '../../../../context/runwayOptions/runwayOptions'
import { IRunway } from './types'

const NAME = 'runway_id'

const RunwaySelect = () => {
    const classes = useStyles()
    const { runway_id } = React.useContext(formCtx)
    const setFormData = React.useContext(formSetterCtx)
    const setRunwayData = React.useContext(runwayInfoSetterCtx)
    const options = React.useContext(runwayOptionsCtx)
    const preparedOptions = React.useMemo(() => {
        return options
            .sort((a, b) => {
                if (a.intersection === null && b.intersection !== null) {
                    return -1
                }

                if (b.intersection === null && a.intersection !== null) {
                    return 1
                }

                return +a.designator - +b.designator || ((a.intersection as string) > b.designator ? -1 : 1)
            })
            .map((el) => ({
                label: `${el.designator}${el.intersection !== null ? ` int. ${el.intersection}` : ''}`,
                value: el.id,
            }))
    }, [options])

    const onSelectChange = React.useCallback(
        (data: string) => {
            const { tora, toda, asda } = options.find((option) => option.id === data) as IRunway
            setFormData((prev) => ({
                ...prev,
                [NAME]: { ...prev[NAME], value: data },
            }))
            setRunwayData({
                tora,
                toda,
                asda,
            })
        },
        [options, setRunwayData, setFormData],
    )

    return React.useMemo(
        () => (
            <FormControl fullWidth css={classes.runwayFormControl}>
                <InputLabel
                    css={[
                        classes.runwaySelectLabel,
                        runway_id.value === null && preparedOptions.length && classes.runwaySelectLabelEmpty,
                    ]}
                >
                    Runway
                </InputLabel>
                <MySelect
                    options={preparedOptions}
                    value={runway_id.value || ''}
                    error={runway_id.error}
                    setValue={onSelectChange}
                    name={NAME}
                    disabled={!preparedOptions.length}
                />
            </FormControl>
        ),
        [runway_id.error, runway_id.value, preparedOptions, onSelectChange, classes],
    )
}

export default React.memo(RunwaySelect)
