/** @jsxImportSource @emotion/react */
import React from 'react'
import { Typography } from '@mui/material'
import { useStyles } from './useStyles'
import { malfunctionsCtx } from '../../../../context/malfunctions/malfunctions'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'

const DEFAULT_TEXT = 'No malfunctions'

const getFirstRowText = (malfunctionsCount: number, isShortVersion: boolean, malfunctionText?: string) => {
    if (!isShortVersion) {
        return malfunctionText || DEFAULT_TEXT
    }

    return malfunctionsCount <= 1 ? malfunctionText || DEFAULT_TEXT : `${malfunctionsCount} aircraft malfunctions`
}

const AircraftMalfunctionsInfo = () => {
    const classes = useStyles()
    const malfunctions = React.useContext(malfunctionsCtx)
    const { height, width } = useWindowDimensions()

    const isShortVersion = height <= 1024 && width <= 768

    return (
        <div css={classes.malfunctionsInfoWrapper}>
            <Typography color="primary">
                {getFirstRowText(malfunctions.length, isShortVersion, malfunctions[0]?.displayText)}
            </Typography>
            {!isShortVersion && (
                <>
                    {malfunctions[1] && (
                        <Typography color="primary">{malfunctions[1]?.displayText || DEFAULT_TEXT}</Typography>
                    )}
                    {malfunctions[2] && (
                        <Typography color="primary">
                            {malfunctions.length > 3
                                ? `And ${malfunctions.length - 2} more...`
                                : malfunctions[2].displayText}
                        </Typography>
                    )}
                </>
            )}
        </div>
    )
}

const AircraftMalfunctionInfoMemoized = React.memo(AircraftMalfunctionsInfo)
export { AircraftMalfunctionInfoMemoized as AircraftMalfunctionInfo }
