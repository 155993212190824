import React from 'react'
import { IRunway } from '../../components/MainForm/Groups/RunwayData/types'

type RunwayOptionsSetterCtxType = React.Dispatch<React.SetStateAction<IRunway[]>>

export const runwayOptionsCtx = React.createContext<IRunway[]>([])
export const runwayOptionsSetterCtx = React.createContext<RunwayOptionsSetterCtxType>(() => null)

const RunwayOptionsContext = ({ children }: { children?: React.ReactNode }) => {
    const [runwayOptions, setRunwayOptions] = React.useState<IRunway[]>([])

    return (
        <runwayOptionsCtx.Provider value={runwayOptions}>
            <runwayOptionsSetterCtx.Provider value={setRunwayOptions}>{children}</runwayOptionsSetterCtx.Provider>
        </runwayOptionsCtx.Provider>
    )
}

export default RunwayOptionsContext
