/** @jsxImportSource @emotion/react */
import React from 'react'
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'

import { useStyles } from './useStyles'
import { formCtx, formSetterCtx } from '../../../../context/form/formContext'

interface IRadioGroup {
    name: 'takeoff_procedure' | 'acu' | 'apu' | 'anti_icing'
    options: { value: number; label: string }[]
    className?: string
    label: string
}

const MyRadioGroup = ({ name, options, className, label }: IRadioGroup) => {
    const classes = useStyles()
    const value = React.useContext(formCtx)[name]
    const setter = React.useContext(formSetterCtx)

    const onChange = React.useCallback(
        (value: string) => {
            setter((prev) => {
                return {
                    ...prev,
                    [name]: { ...prev[name], value: +value },
                }
            })
        },
        [name, setter],
    )

    return React.useMemo(
        () => (
            <FormControl css={className || ''}>
                <FormLabel id={name} css={classes.groupLabel} error={value.error}>
                    {label}
                </FormLabel>
                <RadioGroup
                    row
                    aria-labelledby={`${name}-buttons-group-label`}
                    name={name}
                    value={value.value}
                    onChange={(_e, value) => onChange(value)}
                >
                    {options.map(({ value, label }) => (
                        <FormControlLabel
                            key={value}
                            value={value}
                            control={<Radio />}
                            label={label}
                            css={classes.elementLabel}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        ),
        [name, value.value, value.error, label, options, className, classes, onChange],
    )
}

export default React.memo(MyRadioGroup)
