import React from 'react'

import { userSetterCtx } from '../context/user/userContext'
import useFetch from './useFetch'
import { User } from '../context/user/types'

export default () => {
    const setUser = React.useContext(userSetterCtx)
    const [isAuthProcessing, setIsAuthProcessing] = React.useState(true)
    const fetcher = useFetch()
    React.useEffect(() => {
        fetcher<User>(
            'auth',
            {
                method: 'GET',
                credentials: 'include',
            },
            false,
        )
            .then((user) => {
                setUser(user)
            })
            .finally(() => {
                setTimeout(() => {
                    setIsAuthProcessing(false)
                }, 1500)
            })
    }, [setUser, fetcher])

    return { isAuthProcessing }
}
