/** @jsxImportSource @emotion/react */
import React from 'react'
import { useTheme } from '@mui/material'

const FlightHorizontal = ({ className }: { className?: string }) => {
    const theme = useTheme()

    return (
        <svg
            width="745"
            height="750"
            viewBox="0 0 745 750"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            css={className}
        >
            <g clipPath="url(#clip0_610_855)">
                <path
                    d="M445.739 648.575C495.414 641.47 543.976 617.279 591.659 574.875C617.4 551.984 667.836 492.754 686.542 463.442M722.909 380.792C750.912 275.844 725.159 162.814 664.791 52.5332L697.589 49.6788C736.882 107.055 793.298 269.112 722.068 437.873C718.689 445.876 715.464 452.943 711.484 460.858M663.478 535.248C617.49 592.051 554.424 638.814 478.839 657.561C476.706 658.09 475.385 658.408 473.289 658.865"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M404.236 724.871C604.571 679.447 633.595 630.992 666.828 544.293C667.196 543.332 667.562 542.371 667.923 541.411M691.734 465.696C721.959 297.136 644.583 127.089 495.519 33.7185L523.973 31.1673C585.743 61.692 704.602 183.211 719.518 363.166C720.133 370.587 720.547 378.03 720.745 385.511M713.39 471.037C699.294 543.713 664.542 616.334 604.571 670.078C572.341 698.961 434.971 737.285 404.236 740.912"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
            </g>
            <g clipPath="url(#clip1_610_855)">
                <path
                    d="M285.871 706.604L286.883 709.213L285.396 710.498C285.157 710.36 284.929 710.163 284.711 709.905L283.49 709.043L283.061 709.039L282.729 708.847C282.729 708.851 282.73 708.855 282.731 708.859L274.369 704.615C273.921 705.13 273.443 705.396 273.095 705.087L248.877 691.584L250.614 688.126L275.43 701.13C275.694 701.307 275.705 701.775 275.56 702.334L283.734 706.711L283.735 706.708L284.217 706.905L284.201 707.364L285.67 708.011L285.843 708.065L285.871 706.603L285.871 706.604Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M164.31 675.298C163.552 675.298 141.34 686.22 138.071 687.578L80.2747 697.727L67.7922 700.748C66.4803 701.731 67.2949 704.171 68.7304 703.968L140.385 691.802L182.75 682.97L164.309 675.298L164.31 675.298Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M80.2747 697.727L80.1305 696.842L79.9949 668.768L72.5055 666.178L67.7922 700.748L80.2747 697.727Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M68.731 703.968L78.721 708.567L144.577 705.884L177.325 704.511M185.773 704.157L185.885 704.153M192.673 703.859L203.65 703.408L225.208 700.707L203.65 703.408"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.0422 610.102C-3.20762 597.655 -3.12538 587.92 9.49706 580.559C20.4638 573.918 31.167 569.526 41.7702 565.977L72.5929 555.862C82.9686 553.578 92.9009 553.784 102.551 555.59L124.61 562.676L357.601 661.479"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M64.6169 558.479L92.6803 559.471L120.187 567.784C127.58 570.533 125.573 580.163 116.647 576.779L82.0185 568.014L41.7698 565.977"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M113.258 565.689L113.38 575.952"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M127.653 564.194C138.014 574.267 140.373 599.438 133.287 616.373C132.606 617.887 132.917 618.849 134.291 619.68L148.475 625.422C150.137 625.973 151.399 625.588 152.107 623.915C158.385 604.883 159.961 588.277 146.967 572.156"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.0422 610.103L9.92505 616.402L40.428 635.55L41.1147 631.386L31.1684 623.83L32.9362 620.105L24.4941 614.835L24.8442 614.318L24.8447 614.318L24.8442 614.318L24.8442 614.318L24.8865 614.255L11.0422 610.103Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M424.773 633.164C420.169 633.857 419.663 635.787 422.594 638.752C427.011 641.788 434.194 643.437 442.067 644.203L513.557 643.385C517.065 643.398 515.469 640.55 513.308 639.843C510.436 638.511 506.948 637.489 503.077 636.658L473.523 634.799L424.773 633.164L424.773 633.164Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M434.028 633.474C431.288 631.286 426.918 629.706 423.644 628.068C420.4 626.756 413.687 622.641 408.665 630.93L394.367 652.599"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M227.387 665.544L337.142 671.812L466.778 671.267L547.371 669.248"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M231.473 679.717L382.624 677.264L494.83 673.448L540.622 671.949C546.269 671.42 546.245 670.743 548.726 667.419L566.651 631.05C567.757 628.225 569.223 627.243 571.145 628.597L576.774 633.416L563.363 671.847L563.363 678.047L515.16 680.909L514.07 680.909L379.531 691.539L294.295 698.115M285.911 698.762L272.837 699.77M264.685 700.398L245.265 701.897"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M442.067 644.203L431.653 671.415L431.654 671.415"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M403.439 638.85C401.931 638.506 400.001 638.571 397.821 639.441L383.989 646.463"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M377.076 647.691C382.39 649.658 384.459 657.311 381.697 664.783C380.681 667.535 379.151 669.878 377.358 671.643L363.278 671.702C360.965 668.312 360.504 662.942 362.455 657.663C365.215 650.19 371.761 645.725 377.076 647.691L377.076 647.691Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M378.696 643.309C385.731 645.913 388.47 656.043 384.815 665.937C384.043 668.025 383.049 669.934 381.893 671.625L358.749 671.722C357.254 667.434 357.334 661.935 359.338 656.509C362.994 646.615 371.66 640.705 378.696 643.309L378.696 643.309Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M381.965 645.31L403.407 657.533L403.407 657.738C409.887 661.179 413.586 665.685 413.893 671.49L413.894 671.49"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M407.866 689.301L430.361 695.924C434.099 696.667 434.619 690.636 434.459 687.193"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M57.7871 628.452C69.2434 635.38 86.9455 644.456 99.6723 648.294C119.081 656.464 137.466 664.129 155.68 671.707M158.558 672.904C183.182 683.149 207.572 693.278 233.837 704.335C250.662 712.249 266.577 717.121 282.311 721.392C282.41 721.421 282.509 721.451 282.608 721.48M307.315 727.713C322.46 730.878 338.003 732.9 354.177 733.534C365.001 734.028 415.919 737.493 419.134 720.909C421.659 715.168 426.827 700.822 427.714 695.261"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M254.357 628.062C254.404 626.762 255.054 626.124 256.508 626.368L264.182 629.689C265.979 630.828 267.037 632.47 267.274 634.734C267.431 640.083 267.465 645.384 267.066 650.518C267.069 652.688 266.153 653.748 264.137 653.31L263.961 653.218L257.552 650.592C255.871 650.126 254.569 648.762 254.607 646.425C255.033 640.522 254.917 634.388 254.356 628.063L254.357 628.062Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M238.61 666.185C229.986 660.223 198.429 658.487 188.259 660.723C176.051 663.846 179.95 669.189 180.29 669.923C182.179 673.815 180.512 676.391 174.427 675.809C170.205 675.24 167.842 675.809 166.858 676.455"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M197.518 608.383L199.357 609.101C200.072 609.389 200.269 609.852 200.316 610.522L200.541 615.974C200.563 616.728 200.22 617.209 199.693 617.04L197.727 616.243C196.73 615.836 196.922 615.618 196.84 614.825L196.676 609.325C196.631 608.433 196.965 608.242 197.519 608.383L197.518 608.383Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M183.799 603.055L185.638 603.774C186.353 604.061 186.55 604.524 186.596 605.194L186.821 610.645C186.843 611.4 186.501 611.881 185.974 611.712L184.007 610.915C183.01 610.508 183.203 610.291 183.121 609.497L182.957 603.996C182.911 603.105 183.246 602.914 183.8 603.055L183.799 603.055Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M210.004 613.518L211.842 614.237C212.557 614.524 212.754 614.987 212.801 615.657L213.026 621.108C213.048 621.863 212.706 622.344 212.179 622.175L210.212 621.378C209.215 620.971 209.407 620.753 209.325 619.96L209.161 614.459C209.115 613.568 209.451 613.378 210.004 613.518L210.004 613.518Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M222.918 618.584L224.756 619.302C225.471 619.589 225.668 620.052 225.715 620.722L225.94 626.174C225.962 626.928 225.62 627.41 225.093 627.241L223.126 626.443C222.129 626.036 222.321 625.819 222.239 625.025L222.076 619.525C222.03 618.633 222.365 618.443 222.918 618.583L222.918 618.584Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M235.402 623.481L237.241 624.199C237.956 624.487 238.153 624.949 238.199 625.619L238.424 631.071C238.446 631.825 238.104 632.306 237.577 632.137L235.61 631.34C234.613 630.933 234.806 630.716 234.723 629.922L234.56 624.422C234.514 623.53 234.849 623.34 235.403 623.48L235.402 623.481Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M247.443 628.203L249.282 628.922C249.997 629.209 250.194 629.672 250.241 630.342L250.466 635.793C250.487 636.548 250.146 637.029 249.619 636.86L247.652 636.063C246.655 635.656 246.847 635.439 246.764 634.645L246.601 629.145C246.555 628.253 246.89 628.062 247.444 628.203L247.443 628.203Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M259.977 633.12L261.815 633.838C262.53 634.126 262.727 634.588 262.774 635.258L262.999 640.71C263.021 641.464 262.679 641.945 262.152 641.777L260.185 640.979C259.188 640.572 259.38 640.355 259.298 639.561L259.135 634.061C259.089 633.169 259.424 632.979 259.977 633.119L259.977 633.12Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M273.526 638.254L275.364 638.972C276.079 639.259 276.276 639.722 276.323 640.392L276.548 645.844C276.57 646.598 276.228 647.08 275.701 646.911L273.734 646.113C272.737 645.706 272.929 645.489 272.847 644.695L272.684 639.195C272.638 638.303 272.973 638.113 273.526 638.254L273.526 638.254Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M285.957 643.118L287.795 643.836C288.51 644.124 288.707 644.587 288.754 645.257L288.979 650.708C289.001 651.463 288.659 651.944 288.132 651.775L286.165 650.978C285.168 650.57 285.36 650.353 285.278 649.56L285.114 644.059C285.069 643.167 285.404 642.977 285.957 643.118L285.957 643.118Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M299.21 648.35L301.049 649.068C301.764 649.356 301.961 649.819 302.007 650.489L302.232 655.94C302.254 656.695 301.912 657.176 301.385 657.007L299.418 656.21C298.421 655.803 298.614 655.585 298.531 654.792L298.368 649.292C298.322 648.4 298.657 648.209 299.21 648.35L299.21 648.35Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M311.415 653.295L313.253 654.013C313.969 654.301 314.166 654.764 314.212 655.434L314.437 660.885C314.459 661.64 314.117 662.121 313.59 661.952L311.623 661.155C310.626 660.748 310.819 660.53 310.736 659.737L310.573 654.236C310.527 653.345 310.862 653.154 311.415 653.294L311.415 653.295Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M323.659 658.098L325.497 658.816C326.212 659.103 326.409 659.566 326.456 660.236L326.681 665.687C326.703 666.442 326.361 666.923 325.834 666.754L323.867 665.957C322.87 665.55 323.062 665.332 322.98 664.539L322.816 659.039C322.77 658.147 323.106 657.957 323.659 658.097L323.659 658.098Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.0422 610.103C16.9264 610.716 23.5118 609.273 30.7588 605.568L24.916 614.284L11.0422 610.103V610.103Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.0422 610.102L24.8443 614.318L24.8448 614.318"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M248.791 691.538L273.009 705.04C273.357 705.349 273.835 705.083 274.284 704.568M275.475 702.288C275.618 701.729 275.607 701.261 275.344 701.084L250.528 688.08L248.791 691.539L250.528 688.08"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M273.529 704.186C273.372 703.91 274.23 701.814 274.865 701.962L283.686 706.686C283.073 707.169 282.557 708.257 282.644 708.813L273.529 704.186"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M285.735 708.023L285.622 707.989L284.151 707.343L284.169 706.883L283.686 706.686L283.664 706.747C283.232 706.928 282.505 708.194 282.643 708.804L283.012 709.017L283.441 709.021L284.663 709.883C285.007 710.291 285.378 710.545 285.774 710.659C285.795 710.198 285.764 709.73 285.711 709.259L285.735 708.023V708.023Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M283.012 709.017C283.125 707.987 283.523 707.29 284.168 706.883"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M284.677 708.227L285.502 708.6C285.579 708.644 285.652 708.687 285.721 708.731L285.711 709.259C285.727 709.398 285.74 709.536 285.751 709.675L284.677 708.227L284.677 708.227Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M273.009 705.04C272.236 704.144 273.778 700.634 275.344 701.084"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M295.818 689.659C295.999 689.626 296.302 689.662 296.569 689.843C297.415 690.478 298.305 691.857 298.35 693.213C298.339 693.476 298.267 693.63 298.149 693.712L300.476 692.525C301.594 691.878 299.869 688.043 298.617 688.321L295.745 689.674L295.818 689.659L295.818 689.659Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M295.724 689.685L295.975 689.566M295.128 690.309C295.358 689.719 295.936 689.416 296.568 689.844C297.415 690.478 298.304 691.857 298.35 693.213C298.307 694.18 297.342 693.657 297.342 693.657L297.637 693.467C298.115 692.787 296.962 690.02 295.44 690.21L295.129 690.309L295.128 690.309Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M293.964 695.835L297.634 693.471C298.112 692.791 296.959 690.024 295.437 690.214"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M175.969 721.286L176.647 721.664"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M189.725 741.656C192.656 741.656 195.032 737.229 195.032 731.769C195.032 726.308 192.656 721.882 189.725 721.882C186.794 721.882 184.418 726.308 184.418 731.769C184.418 737.229 186.794 741.656 189.725 741.656Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M190.066 737.472C191.757 737.472 193.127 734.919 193.127 731.768C193.127 728.618 191.757 726.064 190.066 726.064C188.375 726.064 187.005 728.618 187.005 731.768C187.005 734.919 188.375 737.472 190.066 737.472Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M189.725 741.656C192.656 741.656 195.032 737.229 195.032 731.769C195.032 726.308 192.656 721.882 189.725 721.882C186.794 721.882 184.418 726.308 184.418 731.769C184.418 737.229 186.794 741.656 189.725 741.656Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M190.066 737.472C191.757 737.472 193.127 734.919 193.127 731.768C193.127 728.618 191.757 726.064 190.066 726.064C188.375 726.064 187.005 728.618 187.005 731.768C187.005 734.919 188.375 737.472 190.066 737.472Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M186.154 741.626C183.223 741.626 180.854 737.186 180.847 731.739C180.84 726.293 183.113 721.947 186.035 721.825L189.54 721.888C186.695 722.069 184.419 726.423 184.419 731.768C184.419 736.956 186.563 741.21 189.29 741.622L186.154 741.626L186.154 741.626Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M178.579 741.806C175.649 741.806 173.273 737.38 173.273 731.919C173.273 727.575 174.802 723.875 176.893 722.549C177.187 723.199 177.642 723.642 178.07 723.751L178.13 723.764L178.156 724.15L178.412 724.588L178.411 724.588L178.396 724.563L178.387 724.577C177.315 726.386 176.64 728.998 176.639 731.902C176.636 737.287 178.953 741.672 181.831 741.786L181.945 741.789L178.578 741.806L178.579 741.806ZM181.946 741.789L181.969 741.789L181.946 741.789Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M178.387 724.578L178.396 724.564L180.252 727.625C179.64 728.668 179.226 730.237 179.226 731.903C179.226 734.788 180.375 737.173 181.867 737.554C182.3 738.66 182.852 739.593 183.489 740.286C183.694 740.523 183.908 740.735 184.129 740.918C183.464 741.478 182.725 741.791 181.946 741.791C179.015 741.791 176.636 737.361 176.639 731.904C176.641 729 177.315 726.388 178.387 724.579L178.387 724.578Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M185.193 694.256C185.389 693.751 185.889 693.487 186.43 693.852C187.153 694.394 187.912 695.571 187.952 696.729C187.914 697.555 187.093 697.108 187.093 697.108L187.339 696.948C187.747 696.367 186.763 694.006 185.464 694.168L185.193 694.256V694.256Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M177.187 711.675C177.359 713.196 177.296 714.687 176.021 716.019C175.124 716.45 174.669 717.143 174.875 718.226L175.052 720.083C175.046 720.605 175.261 720.912 175.611 721.083L176.032 721.32C175.929 720.629 175.826 719.937 175.723 719.246C175.72 718.491 176.012 718.358 176.509 718.654L179.138 720.478L179.188 720.499C179.535 720.813 179.936 721.069 179.944 721.761L180.026 722.926C179.987 723.752 180.319 724.334 181.131 724.573C181.251 724.756 181.424 724.864 181.646 724.876C181.953 724.842 182.227 724.72 182.471 724.549C183.334 723.009 184.482 722.016 185.771 721.849L185.775 721.635L185.772 709.907L185.772 697.959L187.339 696.95C187.747 696.37 186.763 694.007 185.463 694.169L181.468 695.448C179.24 696.118 177.8 697.35 177.422 699.341L177.187 711.676L177.187 711.675Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M179.7 715.48C179.482 716.094 179.462 716.621 179.542 717.409L180.405 720.522L180.939 723.989C180.966 724.217 181.028 724.41 181.121 724.557"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M184.206 698.968L187.34 696.95C187.748 696.369 186.763 694.006 185.464 694.168"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M175.937 721.265L176.615 721.644"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M178.471 723.376C178.908 723.119 178.983 722.273 178.637 721.486C178.292 720.699 177.657 720.269 177.219 720.527C176.781 720.784 176.707 721.631 177.052 722.418C177.398 723.205 178.033 723.634 178.471 723.376Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M177.083 720.217C177.616 719.903 178.388 720.426 178.809 721.384C179.23 722.342 179.139 723.372 178.607 723.686C178.483 723.758 178.346 723.786 178.203 723.774C178.16 723.77 178.115 723.762 178.071 723.751C177.636 723.641 177.173 723.185 176.88 722.519C176.46 721.561 176.55 720.53 177.083 720.217Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M177.083 720.217L178.056 719.727"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M182.401 714.258C182.517 716.004 182.849 717.524 183.915 718.319C184.732 718.792 184.552 719.287 184.382 719.782L183.221 723.439"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M182.401 714.258L182.381 704.3"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M185.763 706.623L187.584 705.699C187.961 706.133 188.363 706.356 188.656 706.096L209.847 694.271L205.983 692.657L186.691 702.766C186.469 702.915 186.46 703.309 186.581 703.78L185.744 704.228M188.129 692.582C189.183 692.349 190.67 695.611 189.728 696.155L187.727 697.181C187.475 697.317 187.093 697.109 187.093 697.109L187.34 696.95C187.742 696.376 186.744 694.009 185.464 694.168L185.193 694.256C185.293 693.998 185.459 693.801 185.682 693.726L188.129 692.582Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M185.769 704.222L185.741 704.238M185.741 706.643L185.751 706.638"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M188.194 705.397C188.326 705.165 187.605 703.401 187.07 703.526L185.769 704.222M185.75 706.638L188.194 705.397"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M188.632 706.117C189.282 705.362 187.984 702.409 186.667 702.787"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M44.0544 646.179L44.7325 646.557"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M46.6926 648.58L48.0795 647.873"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M46.4719 649.438L48.4849 652.762L48.3801 657.258C48.3714 657.931 48.6936 658.292 49.0043 658.532"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M46.6655 666.7C43.735 666.7 41.3594 662.274 41.3594 656.814C41.3594 652.468 42.8881 648.769 44.9795 647.443C45.2724 648.092 45.7288 648.536 46.1563 648.645L46.1563 648.644L46.1563 648.645L46.2164 648.658L46.2428 649.044L46.4977 649.482C45.4263 651.29 44.7482 653.91 44.7463 656.813C44.7429 662.27 47.1243 666.683 50.0549 666.683L46.6655 666.7V666.7Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M46.4973 649.482L48.2496 652.374C47.6543 653.426 47.2648 655.007 47.2648 656.658C47.2648 657.583 47.3831 658.457 47.5932 659.231C47.9943 660.845 48.7979 661.988 49.8015 662.278L49.8034 662.279L49.8044 662.279L49.8722 662.299C50.4588 663.85 51.2783 665.074 52.2357 665.791L52.2386 665.793C51.5793 666.348 50.8248 666.677 50.0544 666.683L50.0318 666.683L49.9179 666.68C47.0508 666.545 44.7425 662.184 44.7463 656.813C44.7483 653.91 45.4263 651.291 46.4973 649.482V649.482Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M44.0232 646.159L44.7008 646.537"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M45.3048 645.42C45.7424 645.163 46.3777 645.592 46.7235 646.379C47.0692 647.166 46.9947 648.012 46.5566 648.27C46.119 648.527 45.4842 648.098 45.138 647.311C44.7922 646.524 44.8672 645.678 45.3048 645.42Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M45.1685 645.111C45.7013 644.797 46.4741 645.32 46.8954 646.278C47.3157 647.236 47.2253 648.266 46.6924 648.579C46.5689 648.652 46.4318 648.68 46.289 648.667C46.2457 648.664 46.201 648.656 46.1567 648.645L46.1567 648.644L46.1567 648.645C45.7225 648.535 45.2589 648.079 44.966 647.412C44.5453 646.454 44.6362 645.424 45.1685 645.111Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M45.1687 645.111L46.142 644.62"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M57.7795 646.754C60.7101 646.754 63.0862 651.181 63.0862 656.641C63.0862 662.102 60.7101 666.529 57.7795 666.529C54.8485 666.529 52.4729 662.102 52.4729 656.641C52.4729 651.181 54.8485 646.754 57.7795 646.754Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M58.1204 650.938C59.8113 650.938 61.1823 653.491 61.1823 656.641C61.1823 659.792 59.8113 662.345 58.1204 662.345C56.4296 662.345 55.0591 659.792 55.0591 656.641C55.0591 653.491 56.4296 650.938 58.1204 650.938Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M57.7795 646.754C60.7101 646.754 63.0862 651.181 63.0862 656.641C63.0862 662.102 60.7101 666.529 57.7795 666.529C54.8485 666.529 52.4729 662.102 52.4729 656.641C52.4729 651.181 54.8485 646.754 57.7795 646.754Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M54.2085 666.499C51.278 666.499 48.9091 662.059 48.9019 656.612C48.8951 651.166 51.2861 646.847 54.2085 646.725L57.5954 646.761C54.75 646.942 52.4735 651.296 52.4735 656.642C52.4735 661.829 54.6177 666.083 57.3449 666.496L54.2085 666.499Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M45.2417 636.548C45.4138 638.069 45.3508 639.56 44.076 640.892C43.1787 641.324 42.7237 642.016 42.9296 643.099L43.1065 644.956C43.1012 645.478 43.3157 645.785 43.6658 645.956L44.0866 646.193C43.9837 645.502 43.8808 644.81 43.7778 644.119C43.7745 643.364 44.0669 643.231 44.5641 643.527L47.1922 645.351L47.2432 645.372C47.5899 645.686 47.9905 645.942 47.9991 646.634L48.0813 647.799C48.0424 648.625 48.3742 649.208 49.186 649.447C49.3062 649.629 49.4788 649.737 49.7005 649.749C50.0621 649.709 50.3786 649.548 50.6536 649.326L50.6541 649.326L50.6801 649.284L50.7056 649.242L50.732 649.2L50.7373 649.192L50.758 649.159L50.7844 649.118L50.8109 649.077L50.8349 649.04L50.8369 649.037L50.8638 648.996L50.8907 648.956L50.9176 648.917L50.935 648.891L50.9446 648.877L50.972 648.838L50.9994 648.799L51.0268 648.761L51.0364 648.747L51.0547 648.723L51.0826 648.685L51.1105 648.648L51.1384 648.61L51.1403 648.608L51.1667 648.574L51.2235 648.501L51.2456 648.473L51.2519 648.465L51.2807 648.429L51.3096 648.394L51.3384 648.359L51.3524 648.341L51.3668 648.324L51.3961 648.29L51.4255 648.256L51.4548 648.223L51.4611 648.215L51.4841 648.189L51.5139 648.156L51.5438 648.124L51.5717 648.094L51.5736 648.092L51.5765 648.089L51.6337 648.028L51.664 647.997L51.6832 647.977L51.6943 647.966L51.7246 647.936L51.7554 647.906L51.7857 647.876L51.7967 647.865L51.8164 647.846L51.8477 647.817L51.8785 647.788L51.9097 647.76L51.9367 647.736L51.941 647.732L51.9722 647.704L52.0035 647.677L52.029 647.655L52.0357 647.65L52.0675 647.623L52.0992 647.596L52.1309 647.57L52.1473 647.557L52.1632 647.545L52.1954 647.519L52.2276 647.495L52.2598 647.471L52.292 647.446L52.3242 647.423L52.357 647.4L52.3877 647.378L52.3897 647.377L52.4224 647.355L52.4551 647.332L52.4882 647.31L52.5094 647.296L52.5209 647.289L52.5541 647.268C52.9388 647.008 53.363 646.815 53.8266 646.728L53.8294 646.51L53.827 632.871L53.5 633.236L53.0913 632.69L45.4696 627.932L45.2417 636.551L45.2417 636.548Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M47.7554 640.353C47.5376 640.967 47.5174 641.494 47.5968 642.282L48.46 645.395L48.9942 648.862C49.0216 649.09 49.0827 649.283 49.176 649.43"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M50.4868 639.152C50.6032 640.898 50.935 642.419 52.0016 643.213C52.8172 643.687 52.6378 644.181 52.4676 644.676L51.274 648.434"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M50.4868 639.152L50.4709 631.052"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M30.7558 605.571L50.2315 617.697L50.0949 622.876L58.2657 627.918L53.4996 633.233L53.0908 632.687L24.4941 614.835L24.8442 614.318L24.8447 614.318L24.8442 614.318L24.8442 614.318L30.7558 605.571Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M92.6806 559.471L113.257 565.69L120.187 567.785C127.58 570.533 125.573 580.163 116.647 576.779L113.379 575.952L82.0183 568.013L92.6806 559.471V559.471Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M363.41 704.978L368.26 707.445L367.663 709.155L362.972 706.888L363.41 704.978Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M387.484 731.403L386.451 727.603L384.231 728.441L383.995 728.471L378.906 729.426L377.85 731.883L387.484 731.403Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M352.466 730.998L352.581 729.643L354.82 729.675L354.562 731.4L352.466 730.998Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M341.939 729.826L342.054 728.471L344.292 728.503L344.034 730.229L341.939 729.826Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M159.579 676.304C159.508 676.016 159.467 675.759 159.419 675.468L157.988 671.211L159.817 671.092L159.976 671.052L161.328 669.739L157.034 665.92C156.834 665.92 156.924 665.93 156.716 665.999L152.661 667.67L155.126 669.859L155.126 670.057L157.074 675.866L159.579 676.304V676.304Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M127.281 623.477C128.066 623.608 128.902 622.516 129.149 621.037C129.395 619.558 128.959 618.253 128.175 618.122C127.39 617.991 126.554 619.083 126.307 620.562C126.06 622.04 126.496 623.345 127.281 623.477Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M121.929 589.029C122.459 589.021 122.877 588.205 122.863 587.207C122.849 586.208 122.408 585.405 121.878 585.412C121.348 585.42 120.93 586.236 120.944 587.234C120.958 588.233 121.399 589.036 121.929 589.029Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M156.689 662.33C156.654 661.848 155.969 661.505 155.159 661.563C154.349 661.622 153.72 662.06 153.755 662.542C153.79 663.024 154.475 663.368 155.285 663.309C156.095 663.251 156.724 662.812 156.689 662.33Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M150.316 664.905C150.281 664.423 149.596 664.08 148.786 664.138C147.976 664.197 147.347 664.635 147.382 665.117C147.417 665.599 148.102 665.943 148.912 665.884C149.722 665.826 150.351 665.387 150.316 664.905Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M119.293 579.939L117.454 582.47C117.317 582.599 117.353 582.831 116.825 582.694L116.65 582.653L114.186 582.008L114.098 581.99L113.616 581.753L112.347 581.466L111.821 581.225L111.823 581.192L111.864 580.951L111.922 580.922L112.44 581.031L113.662 581.289L114.231 581.266L115.638 581.63C115.913 581.702 116.062 581.506 116.152 581.286L117.199 579.545L117.287 579.552L119.293 579.94L119.293 579.939Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M112.369 581.452L112.377 581.42L112.462 581.017"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M113.637 581.739L113.684 581.275"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M114.119 581.976L114.253 581.252"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M115.788 581.676L117.035 581.955L117.057 581.951L117.269 582.156"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M53.7021 580.033L51.8627 582.563C51.7256 582.693 51.7617 582.925 51.2336 582.788L51.0591 582.747L48.5945 582.102L48.507 582.083L48.0247 581.846L46.7561 581.56L46.2295 581.319L46.2319 581.286L46.2723 581.044L46.3305 581.015L46.8489 581.125L48.0708 581.382L48.6397 581.359L50.0468 581.723C50.3224 581.795 50.4705 581.6 50.5609 581.38L51.6073 579.639L51.6958 579.646L53.7016 580.033L53.7021 580.033Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M46.7778 581.546L46.786 581.513L46.8711 581.111"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M48.0459 581.832L48.0925 581.369"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M48.5281 582.07L48.6613 581.345"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M50.1973 581.769L51.4442 582.049L51.4663 582.045L51.6784 582.25"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M87.163 595.135C87.2418 594.614 87.1394 594.116 86.8287 593.778C86.1843 593.075 85.1437 593.577 84.2266 594.42C83.3101 595.263 82.8931 596.707 83.5399 597.411C84.1223 598.046 85.1499 597.671 86.0204 596.971C85.6645 597.044 85.3149 597.041 84.9749 596.93L84.0511 596.059C84.305 595.536 84.972 595.277 85.8559 595.156L87.1634 595.135L87.163 595.135Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M84.9748 596.929L84.051 596.058C84.3049 595.535 84.9719 595.276 85.8558 595.155L87.2196 595.133L88.0362 595.972C87.6192 596.256 87.2042 596.515 86.795 596.705L86.4632 596.396C86.3155 596.37 86.1968 596.438 86.178 596.673L86.3997 596.864C85.9126 597.032 85.4355 597.079 84.9748 596.928L84.9748 596.929Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M84.9749 596.929L85.0282 596.853C85.5336 596.251 86.6988 596.058 88.0362 595.972"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M86.178 596.674C86.1967 596.439 86.3155 596.37 86.4631 596.396L87.0801 596.97C87.2807 597.251 87.1018 597.432 86.833 597.238L86.178 596.674Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M284.492 718.87L285.17 719.249"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M298.248 739.24C301.179 739.24 303.555 734.814 303.555 729.353C303.555 723.893 301.179 719.466 298.248 719.466C295.318 719.466 292.942 723.893 292.942 729.353C292.942 734.814 295.318 739.24 298.248 739.24Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M298.59 735.057C300.28 735.057 301.651 732.503 301.651 729.353C301.651 726.203 300.28 723.649 298.59 723.649C296.899 723.649 295.528 726.203 295.528 729.353C295.528 732.503 296.899 735.057 298.59 735.057Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M298.248 739.24C301.179 739.24 303.555 734.814 303.555 729.353C303.555 723.893 301.179 719.466 298.248 719.466C295.318 719.466 292.942 723.893 292.942 729.353C292.942 734.814 295.318 739.24 298.248 739.24Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M298.59 735.057C300.28 735.057 301.651 732.503 301.651 729.353C301.651 726.203 300.28 723.649 298.59 723.649C296.899 723.649 295.528 726.203 295.528 729.353C295.528 732.503 296.899 735.057 298.59 735.057Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M294.677 739.211C291.747 739.211 289.377 734.771 289.371 729.324C289.364 723.878 291.637 719.532 294.559 719.41L298.064 719.473C295.219 719.654 292.942 724.008 292.942 729.353C292.942 734.541 295.087 738.795 297.814 739.207L294.677 739.211L294.677 739.211Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M287.103 739.391C284.173 739.391 281.797 734.965 281.797 729.504C281.797 725.16 283.326 721.46 285.417 720.134C285.711 720.784 286.166 721.227 286.594 721.336L286.654 721.349L286.68 721.735L286.935 722.173L286.935 722.173L286.92 722.148L286.91 722.162C285.839 723.971 285.164 726.583 285.163 729.487C285.16 734.872 287.477 739.257 290.355 739.371L290.469 739.374L287.102 739.391L287.103 739.391ZM290.47 739.374L290.493 739.374L290.47 739.374Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M286.91 722.163L286.92 722.148L288.775 725.21C288.164 726.252 287.75 727.822 287.75 729.488C287.75 732.372 288.899 734.757 290.39 735.138C290.823 736.245 291.375 737.178 292.013 737.871C292.218 738.107 292.431 738.319 292.653 738.503C291.987 739.063 291.248 739.375 290.469 739.375C287.539 739.375 285.159 734.945 285.163 729.488C285.164 726.585 285.839 723.972 286.91 722.164L286.91 722.163Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M285.711 709.26C285.883 710.781 285.82 712.272 284.545 713.604C283.647 714.035 283.192 714.728 283.398 715.81L283.575 717.668C283.57 718.19 283.784 718.496 284.135 718.668L284.555 718.905C284.452 718.213 284.35 717.522 284.247 716.831C284.244 716.076 284.536 715.943 285.033 716.239L287.661 718.063L287.712 718.084C288.059 718.398 288.459 718.654 288.468 719.345L288.55 720.511C288.511 721.336 288.843 721.919 289.655 722.158C289.775 722.34 289.948 722.449 290.169 722.461C290.477 722.427 290.751 722.305 290.995 722.134C291.858 720.594 293.006 719.601 294.295 719.434L294.298 719.22L294.295 707.491L294.296 695.544L297.641 693.462C298.162 692.868 296.794 689.553 295.128 690.31L289.991 693.033C287.763 693.703 286.324 694.935 285.946 696.926L285.71 709.261L285.711 709.26Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M288.224 713.065C288.006 713.678 287.986 714.205 288.066 714.994L288.929 718.106L289.463 721.574C289.49 721.802 289.551 721.994 289.645 722.141"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M284.46 718.85L285.138 719.228"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M286.994 720.961C287.432 720.704 287.506 719.857 287.16 719.07C286.815 718.283 286.18 717.854 285.742 718.111C285.304 718.369 285.23 719.215 285.575 720.002C285.921 720.789 286.556 721.218 286.994 720.961Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M285.606 717.802C286.139 717.488 286.912 718.011 287.333 718.969C287.753 719.926 287.663 720.957 287.13 721.27C287.007 721.343 286.87 721.371 286.727 721.358C286.683 721.354 286.639 721.347 286.594 721.335C286.16 721.225 285.697 720.77 285.403 720.103C284.983 719.145 285.073 718.114 285.606 717.802V717.802Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M285.606 717.802L286.579 717.311"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M290.924 711.842C291.041 713.588 291.373 715.109 292.439 715.904C293.255 716.377 293.075 716.871 292.905 717.366L291.744 721.023"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M290.924 711.842L290.904 701.885"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_610_855">
                    <rect
                        width="396.613"
                        height="677.671"
                        fill="white"
                        transform="matrix(0.898388 0.439204 0.439204 -0.898388 229.373 574.927)"
                    />
                </clipPath>
                <clipPath id="clip1_610_855">
                    <rect width="577" height="188" fill="white" transform="translate(0 554)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default FlightHorizontal
