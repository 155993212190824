/** @jsxImportSource @emotion/react */
import React from 'react'
import { FormControl, InputLabel } from '@mui/material'
import { formCtx, formSetterCtx } from '../../../../../context/form/formContext'
import useStyles from './useStyles'
import MySelect from '../../../../ui/components/MySelect/MySelect'

const NAME = 'surface_condition'

const options = [
    { value: 0, label: 'Dry' },
    { value: 1000, label: 'Wet' },
    { value: 2032, label: 'Standing water 3.2 mm or equivalent' },
    { value: 2064, label: 'Standing water 6.4 mm or equivalent' },
    { value: 2127, label: 'Standing water 12.7 mm or equivalent' },
    { value: 4000, label: 'Compacted snow' },
    { value: 7000, label: 'Ice' },
]

const RunwaySurfaceConditionSelect = () => {
    const classes = useStyles()
    const { surface_condition } = React.useContext(formCtx)
    const setter = React.useContext(formSetterCtx)

    const onSelectChange = React.useCallback(
        (data: number) => {
            setter((prev) => ({ ...prev, [NAME]: { ...prev[NAME], value: data } }))
        },
        [setter],
    )

    return React.useMemo(
        () => (
            <FormControl fullWidth css={classes.runwaySurfaceFormControl}>
                <InputLabel css={classes.runwaySurfaceSelectLabel} error={surface_condition.error}>
                    Runway surface condition
                </InputLabel>
                <MySelect
                    options={options}
                    value={surface_condition.value as number}
                    setValue={onSelectChange}
                    name={NAME}
                    error={surface_condition.error}
                />
            </FormControl>
        ),
        [surface_condition.value, surface_condition.error, onSelectChange, classes],
    )
}

export default RunwaySurfaceConditionSelect
