import React from 'react'
import { MalfunctionsCtxType, MalfunctionsSetterCtxType } from './types'

export const malfunctionsCtx = React.createContext<MalfunctionsCtxType>([])
export const malfunctionsSetterCtx = React.createContext<MalfunctionsSetterCtxType>(() => null)

const MalfunctionsContext = ({ children }: { children?: React.ReactNode }) => {
    const [malfunctions, setMalfunctions] = React.useState<MalfunctionsCtxType>([])

    return (
        <malfunctionsCtx.Provider value={malfunctions}>
            <malfunctionsSetterCtx.Provider value={setMalfunctions}>{children}</malfunctionsSetterCtx.Provider>
        </malfunctionsCtx.Provider>
    )
}

export default MalfunctionsContext
