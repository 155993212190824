import { useMemo } from 'react'

import { useTheme } from '@mui/material'
import { css } from '@emotion/react'

export default () => {
    const theme = useTheme()

    return useMemo(
        () => ({
            runwaySurfaceFormControl: css`
                margin-top: ${theme.spacing(2)};
            `,
            runwaySurfaceSelectLabel: css`
                top: -5px;
                color: ${theme.palette.info.main};
                font-size: 16px;
                line-height: 19px;
            `,
        }),
        [theme],
    )
}
