import { useTheme } from '@mui/material'
import { css } from '@emotion/react'

export default () => {
    const theme = useTheme()

    return {
        radioGroup: css``,
        takeoffWeightInput: css`
            width: 100%;
            margin-top: ${theme.spacing(1)};
            color: ${theme.palette.info.main};

            & .MuiFormLabel-root {
                top: -5px;
            }
        `,
    }
}
