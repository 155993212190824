import React from 'react'

export default (delay = 1000) => {
    const data = React.useRef<NodeJS.Timeout | null>(null)

    return React.useCallback(
        (callback: Function) => {
            if (data.current !== null) {
                clearTimeout(data.current)
            }
            data.current = setTimeout(() => {
                callback()
            }, delay)
        },
        [delay],
    )
}
