import { css } from '@emotion/react'
import { useTheme } from '@mui/material'

export const useStyles = () => {
    const theme = useTheme()

    return {
        wrapper: css`
            background: ${theme.palette.primary.main};
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: ${theme.spacing(2.5)};
            height: 41px;
        `,
        title: css`
            font-size: 20px;
            font-weight: 700;
            color: ${theme.palette.secondary.main};
        `,
        headerButton: css`
            color: ${theme.palette.secondary.main};
            font-size: 16px;
            font-weight: 400;
        `,
        themeToggleButton: css`
            color: ${theme.palette.secondary.main};
        `,
    }
}
