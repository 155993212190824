/** @jsxImportSource @emotion/react */
import * as React from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'

import AircraftMalfunctionsModalSelect from './AircraftMalfunctionsModalSelect'
import { useStyles } from './useStyles'
import { Button, DialogActions, IconButton } from '@mui/material'
import { modalSetterCtx } from '../../../context/modal/modalContext'
import XMark from '../../ui/Icons/XMark'
import { formCtx, formSetterCtx, initialMalfunctions } from '../../../context/form/formContext'
import { malfunctionsSetterCtx } from '../../../context/malfunctions/malfunctions'

export enum MalfunctionsSelectsNames {
    GROUND_SPOILERS = 'ground_spoilers',
    ANTI_SKID = 'anti_skid',
    THRUST_REVERSE = 'thrust_reverse',
    LANDING_GEAR = 'landing_gear',
    HYDRAULIC_PUMPS = 'hydraulic_pumps',
    NW_STEERING = 'nw_steering',
    APR = 'apr',
    ECPRV = 'ecprv',
}

const SELECTS_LIST = [
    { name: MalfunctionsSelectsNames.GROUND_SPOILERS, label: 'Ground spoilers' },
    { name: MalfunctionsSelectsNames.ANTI_SKID, label: 'Anti-skid' },
    { name: MalfunctionsSelectsNames.THRUST_REVERSE, label: 'Thrust reverse' },
    { name: MalfunctionsSelectsNames.LANDING_GEAR, label: 'Landing gear' },
    { name: MalfunctionsSelectsNames.HYDRAULIC_PUMPS, label: 'Hydraulic pumps' },
    { name: MalfunctionsSelectsNames.NW_STEERING, label: 'Nosewheel steering' },
    { name: MalfunctionsSelectsNames.APR, label: 'APR' },
    { name: MalfunctionsSelectsNames.ECPRV, label: 'ECPRV' },
]

const SELECTS_OPTIONS = {
    [MalfunctionsSelectsNames.GROUND_SPOILERS]: [
        { label: 'Armed', value: 0 },
        { label: 'One pair inoperative', value: 3, displayText: 'Ground spoilers: 1 pair INOP' },
    ],
    [MalfunctionsSelectsNames.ANTI_SKID]: [
        { label: 'On', value: 0 },
        { label: 'One channel inoperative', value: 1, displayText: 'Anti-skid: 1 CH INOP' },
    ],
    [MalfunctionsSelectsNames.THRUST_REVERSE]: [
        { label: 'All', value: 1 },
        { label: 'One inoperative', value: 2, displayText: 'One reverser inoperative' },
    ],
    [MalfunctionsSelectsNames.LANDING_GEAR]: [
        { label: 'Up', value: 0 },
        { label: 'Extended', value: 1, displayText: 'Landing gear extended' },
    ],
    [MalfunctionsSelectsNames.HYDRAULIC_PUMPS]: [
        { label: 'Normal', value: 0 },
        { label: 'ACMP 1B inoperative', value: 2, displayText: 'ACMP 1B INOP' },
        { label: 'ACMP 2B inoperative', value: 3, displayText: 'ACMP 2B INOP' },
        { label: 'EDP 1 or 2 inoperative', value: 4, displayText: 'EDP 1 or 2 INOP' },
        { label: 'IDG 1 or 2 inoperative', value: 5, displayText: 'IDG 1 or 2 INOP' },
    ],
    [MalfunctionsSelectsNames.NW_STEERING]: [
        { label: 'Normal', value: 0 },
        { label: 'Inoperative', value: 1, displayText: 'N/W steering INOP' },
    ],
    [MalfunctionsSelectsNames.APR]: [
        { label: 'Armed', value: 0 },
        { label: 'Off', value: 1, displayText: 'APR OFF' },
    ],
    [MalfunctionsSelectsNames.ECPRV]: [
        { label: 'Normal', value: 0 },
        { label: 'Inoperative', value: 1, displayText: 'Engine Cowl PRV INOP' },
    ],
}

const AircraftMalfunctionsModal = () => {
    const classes = useStyles()
    const { ground_spoilers, anti_skid, thrust_reverse, landing_gear, hydraulic_pumps, nw_steering, apr, ecprv } =
        React.useContext(formCtx)
    const setFormData = React.useContext(formSetterCtx)
    const setMalfunctions = React.useContext(malfunctionsSetterCtx)
    const hideModal = React.useContext(modalSetterCtx).hideModal

    const onClear = React.useCallback(() => {
        setMalfunctions([])
        setFormData((prev) => ({
            ...prev,
            ...initialMalfunctions,
        }))
    }, [setFormData, setMalfunctions])

    const isClearEnabled = React.useMemo(() => {
        return [
            [ground_spoilers.value, initialMalfunctions.ground_spoilers.value],
            [anti_skid.value, initialMalfunctions.anti_skid.value],
            [thrust_reverse.value, initialMalfunctions.thrust_reverse.value],
            [landing_gear.value, initialMalfunctions.landing_gear.value],
            [hydraulic_pumps.value, initialMalfunctions.hydraulic_pumps.value],
            [nw_steering.value, initialMalfunctions.nw_steering.value],
            [apr.value, initialMalfunctions.apr.value],
            [ecprv.value, initialMalfunctions.ecprv.value],
        ].some(([currVal, initValue]) => currVal !== initValue)
    }, [
        ground_spoilers.value,
        anti_skid.value,
        thrust_reverse.value,
        landing_gear.value,
        hydraulic_pumps.value,
        nw_steering.value,
        apr.value,
        ecprv.value,
    ])

    return React.useMemo(
        () => (
            <Dialog open={true} css={classes.dialogWrapper} onClose={hideModal}>
                <DialogTitle css={classes.dialogTitle}>Set malfunctions</DialogTitle>
                <div css={classes.inputsWrapper}>
                    {SELECTS_LIST.map((select) => (
                        <AircraftMalfunctionsModalSelect
                            key={select.name}
                            label={select.label}
                            options={SELECTS_OPTIONS[select.name]}
                            name={select.name}
                        />
                    ))}
                </div>
                <DialogActions css={classes.dialogActions}>
                    <Button
                        variant="contained"
                        css={classes.actionButton}
                        onClick={onClear}
                        color="error"
                        disabled={!isClearEnabled}
                    >
                        Clear
                    </Button>
                    <Button variant="contained" css={classes.actionButton} onClick={hideModal}>
                        Done
                    </Button>
                </DialogActions>
                <IconButton onClick={hideModal} css={classes.closeIcon}>
                    <XMark width={36} height={36} />
                </IconButton>
            </Dialog>
        ),
        [hideModal, classes, onClear, isClearEnabled],
    )
}

export default React.memo(AircraftMalfunctionsModal)
