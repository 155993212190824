/** @jsxImportSource @emotion/react */
import React from 'react'
import { Typography } from '@mui/material'

import useStyles from './useStyles'

const RunwayInfoRow = ({ name, data }: { name: string; data: null | number }) => {
    const classes = useStyles()

    return (
        <div css={classes.infoRowWrapper}>
            <Typography css={classes.infoRowName}>{name}</Typography>
            <div css={classes.dotedLine} />
            <Typography css={classes.infoRowData}>{data === null ? 'N/A' : `${data} m`}</Typography>
        </div>
    )
}

export default RunwayInfoRow
