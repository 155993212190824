/** @jsxImportSource @emotion/react */
import React from 'react'
import { Typography } from '@mui/material'

import useStyles from './useStyles'
import InfoRow from './InfoRow'
import { runwayInfoCtx } from '../../../../../context/runwayInfo/runwayInfoContext'

const RunwayInfo = () => {
    const classes = useStyles()
    const { tora, toda, asda } = React.useContext(runwayInfoCtx)

    return React.useMemo(
        () => (
            <div css={classes.runwayInfoWrapper}>
                <Typography css={classes.title}>Runway data</Typography>
                <InfoRow name="tora" data={tora} />
                <InfoRow name="toda" data={toda} />
                <InfoRow name="asda" data={asda} />
            </div>
        ),
        [tora, toda, asda, classes],
    )
}

export default React.memo(RunwayInfo)
