/** @jsxImportSource @emotion/react */
import React from 'react'
import { useTheme } from '@mui/material'

const FlightVertical = ({ className }: { className?: string }) => {
    const theme = useTheme()

    return (
        <svg
            width="449"
            height="614"
            viewBox="0 0 449 614"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            css={className}
        >
            <g clipPath="url(#clip0_624_1178)">
                <path
                    d="M255.092 133.228C301.791 163.737 338.923 210.325 366.166 274.197C380.873 308.677 402.583 390.211 407.007 427.589M402.925 525.201C379.369 640.511 297.485 738.133 181.971 816.636L213.712 834.995C281.781 797.899 418.885 667.195 430.28 469.322C430.82 459.937 431.052 451.537 430.941 441.953M419.177 346.838C400.763 269.776 360.124 194.358 292.988 140.222C291.094 138.694 289.916 137.757 288.023 136.317"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M342.971 107.591C402.665 166.168 429.207 244.089 419.955 344.148C419.853 345.257 419.747 346.365 419.638 347.47M406.291 432.375C353.549 610.569 191.309 739.079 -5.5 759L22 775C99.52 774.683 279.708 713.05 383.71 545.23C387.999 538.31 392.095 531.272 395.991 524.095M430.82 437.474C452.485 360.139 453.244 273.041 419.184 192.309C400.879 148.921 374.984 120.252 345.71 102.122"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
            </g>
            <g clipPath="url(#clip1_624_1178)">
                <path
                    d="M230.807 114.043L231.564 115.992L230.451 116.953C230.273 116.849 230.103 116.702 229.94 116.51L229.026 115.865L228.705 115.863L228.457 115.719C228.457 115.722 228.457 115.725 228.458 115.728L222.205 112.556C221.869 112.941 221.512 113.14 221.252 112.909L203.138 102.818L204.438 100.234L222.998 109.952C223.195 110.084 223.203 110.434 223.096 110.852L229.209 114.123L229.21 114.12L229.57 114.267L229.558 114.611L230.657 115.094L230.786 115.135L230.807 114.042V114.043Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M139.89 90.6471C139.323 90.6471 122.71 98.8097 120.265 99.824L77.0386 107.409L67.7028 109.666C66.7216 110.401 67.3309 112.224 68.4045 112.072L121.996 102.981L153.681 96.3805L139.889 90.6471H139.89Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M77.0386 107.409L76.9307 106.748L76.8293 85.7675L71.2278 83.8322L67.7028 109.666L77.0386 107.409Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M68.4049 112.072L75.8766 115.51L125.131 113.504L149.624 112.479M155.942 112.214L156.026 112.211M161.103 111.991L169.313 111.654L185.436 109.636L169.313 111.654"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M25.2587 41.9261C14.6011 32.6237 14.6626 25.3488 24.1031 19.848C32.3052 14.885 40.3103 11.6031 48.2406 8.95054L71.2933 1.39181C79.0535 -0.315163 86.482 -0.160866 93.6991 1.18824L110.198 6.48395L284.455 80.32"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M65.3279 3.34766L86.3169 4.08857L106.89 10.3014C112.419 12.3551 110.918 19.552 104.242 17.0232L78.3428 10.4726L48.2402 8.95053"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M101.707 8.73584L101.798 16.405"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M112.473 7.61792C120.223 15.1457 121.987 33.9562 116.687 46.6121C116.178 47.7433 116.411 48.4626 117.438 49.0834L128.047 53.3742C129.29 53.786 130.234 53.4986 130.763 52.2484C135.459 38.0251 136.637 25.6159 126.919 13.5686"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M25.2587 41.9265L24.4232 46.6342L47.2367 60.9435L47.7503 57.8313L40.3114 52.1849L41.6335 49.4014L35.3196 45.4631L35.5814 45.0768H35.5818H35.5814V45.0765L35.6131 45.0293L25.2587 41.9265Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M334.694 59.1602C331.25 59.6784 330.872 61.1207 333.064 63.3359C336.368 65.6054 341.74 66.8372 347.628 67.4094L401.097 66.7987C403.72 66.8081 402.527 64.6799 400.91 64.1516C398.762 63.156 396.154 62.3921 393.258 61.771L371.154 60.3823L334.694 59.1605V59.1602Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M341.616 59.3919C339.566 57.7572 336.298 56.576 333.85 55.3521C331.423 54.3717 326.402 51.2962 322.647 57.491L311.953 73.6843"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M187.066 83.3582L269.154 88.0424L366.11 87.6353L426.386 86.1261"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M190.122 93.9496L303.17 92.1167L387.09 89.2649L421.339 88.1446C425.562 87.7489 425.545 87.2436 427.4 84.759L440.806 57.5805C441.633 55.4693 442.73 54.7356 444.167 55.7473L448.377 59.3483L438.347 88.0683V92.7019L402.295 94.8408H401.48L300.857 102.785L237.108 107.698M230.837 108.182L221.058 108.935M214.962 109.405L200.437 110.525"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M347.628 67.4095L339.84 87.7454H339.84"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M318.738 63.4093C317.61 63.1525 316.166 63.2011 314.536 63.851L304.191 69.0989"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M299.021 70.0163C302.995 71.4866 304.543 77.2053 302.477 82.7895C301.717 84.8461 300.573 86.5969 299.232 87.9158L288.701 87.96C286.971 85.4266 286.626 81.4134 288.085 77.4682C290.15 71.8837 295.046 68.5471 299.02 70.0167L299.021 70.0163Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M300.232 66.7416C305.494 68.6874 307.543 76.258 304.809 83.6516C304.231 85.2122 303.488 86.639 302.623 87.9025L285.314 87.9748C284.195 84.7702 284.255 80.6614 285.754 76.6061C288.489 69.2125 294.97 64.7958 300.232 66.7419L300.232 66.7416Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M302.677 68.2367L318.714 77.3715V77.5243C323.56 80.0963 326.327 83.4635 326.557 87.8014H326.557"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M322.049 101.112L338.873 106.062C341.669 106.616 342.058 102.109 341.938 99.537"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M60.22 55.6387C68.7883 60.8161 82.0279 67.599 91.5465 70.467C106.063 76.5726 119.813 82.3003 133.435 87.964M135.588 88.8585C154.005 96.5143 172.246 104.084 191.89 112.347C204.474 118.261 216.377 121.902 228.145 125.094C228.218 125.116 228.292 125.138 228.366 125.159M246.846 129.818C258.173 132.183 269.797 133.694 281.894 134.167C289.99 134.537 328.072 137.126 330.476 124.733C332.365 120.442 336.23 109.722 336.893 105.566"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M207.237 55.3477C207.272 54.3763 207.759 53.8994 208.846 54.0814L214.585 56.5634C215.929 57.4144 216.72 58.6419 216.898 60.3338C217.016 64.3311 217.041 68.2925 216.743 72.1287C216.745 73.7504 216.06 74.5431 214.552 74.2158L214.42 74.1471L209.627 72.1844C208.37 71.8362 207.395 70.817 207.424 69.0701C207.743 64.6591 207.656 60.0748 207.237 55.3481L207.237 55.3477Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M195.46 83.8372C189.01 79.3813 165.408 78.084 157.801 79.7554C148.671 82.0893 151.587 86.0823 151.841 86.6307C153.255 89.5394 152.008 91.4639 147.457 91.0295C144.299 90.6043 142.531 91.0291 141.795 91.5118"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M164.727 40.6414L166.102 41.178C166.637 41.3931 166.785 41.7387 166.819 42.2394L166.987 46.3137C167.004 46.8773 166.748 47.2369 166.354 47.1107L164.883 46.5147C164.137 46.2108 164.281 46.0482 164.219 45.4552L164.097 41.3449C164.063 40.6784 164.313 40.536 164.727 40.641L164.727 40.6414Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M154.466 36.66L155.841 37.1966C156.376 37.4113 156.523 37.7573 156.558 38.2579L156.727 42.3319C156.743 42.8958 156.487 43.2555 156.093 43.1293L154.622 42.5333C153.876 42.2294 154.02 42.0668 153.959 41.4737L153.836 37.3631C153.802 36.697 154.052 36.5546 154.466 36.6596L154.466 36.66Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M174.065 44.4787L175.44 45.0156C175.975 45.2304 176.122 45.5764 176.157 46.077L176.325 50.1509C176.342 50.7149 176.086 51.0746 175.692 50.9483L174.221 50.3524C173.475 50.0481 173.619 49.8855 173.557 49.2928L173.435 45.1822C173.401 44.5157 173.651 44.3736 174.065 44.4783L174.065 44.4787Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M183.723 48.2642L185.098 48.8012C185.633 49.0159 185.781 49.3619 185.816 49.8625L185.984 53.9365C186 54.5004 185.744 54.8601 185.35 54.7338L183.879 54.1379C183.133 53.8336 183.277 53.671 183.216 53.0783L183.094 48.9677C183.059 48.3012 183.31 48.1592 183.724 48.2638L183.723 48.2642Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M193.061 51.9237L194.436 52.4604C194.97 52.6754 195.118 53.0211 195.152 53.5217L195.321 57.596C195.337 58.1596 195.082 58.5193 194.687 58.393L193.216 57.7971C192.47 57.4932 192.615 57.3306 192.553 56.7375L192.43 52.6272C192.396 51.9608 192.647 51.8184 193.061 51.9234L193.061 51.9237Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M202.067 55.4532L203.442 55.9898C203.976 56.2045 204.124 56.5505 204.159 57.0512L204.327 61.1251C204.343 61.689 204.088 62.0487 203.693 61.9225L202.222 61.3265C201.477 61.0226 201.621 60.86 201.559 60.2669L201.437 56.1567C201.402 55.4902 201.653 55.3478 202.067 55.4528L202.067 55.4532Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M211.44 59.1271L212.815 59.6637C213.35 59.8788 213.498 60.2244 213.532 60.7251L213.701 64.7994C213.717 65.363 213.461 65.7227 213.067 65.5964L211.596 65.0008C210.85 64.6965 210.994 64.534 210.933 63.9409L210.81 59.8306C210.776 59.1642 211.027 59.0217 211.441 59.1268L211.44 59.1271Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M221.574 62.9641L222.949 63.5007C223.484 63.7154 223.631 64.0614 223.666 64.5621L223.834 68.636C223.85 69.1999 223.595 69.5596 223.201 69.4334L221.73 68.8374C220.984 68.5331 221.128 68.3705 221.066 67.7778L220.944 63.6672C220.91 63.0007 221.16 62.8587 221.574 62.9637L221.574 62.9641Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M230.871 66.5992L232.246 67.1358C232.781 67.3505 232.928 67.6965 232.963 68.1972L233.131 72.2711C233.148 72.8351 232.892 73.1947 232.498 73.0685L231.027 72.4725C230.281 72.1682 230.425 72.0057 230.363 71.413L230.241 67.3023C230.207 66.6359 230.458 66.4938 230.872 66.5988L230.871 66.5992Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M240.783 70.5091L242.159 71.0457C242.693 71.2604 242.841 71.6064 242.875 72.1071L243.044 76.181C243.06 76.745 242.804 77.1046 242.41 76.9784L240.939 76.3824C240.193 76.0785 240.338 75.9159 240.276 75.3228L240.153 71.2126C240.119 70.5461 240.37 70.4037 240.784 70.5087L240.783 70.5091Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M249.912 74.2042L251.287 74.7412C251.822 74.956 251.969 75.302 252.004 75.8026L252.172 79.8765C252.188 80.4405 251.933 80.7998 251.539 80.6739L250.068 80.0779C249.322 79.7737 249.466 79.6111 249.404 79.0184L249.282 74.9078C249.247 74.2413 249.498 74.0992 249.912 74.2039L249.912 74.2042Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M259.069 77.7934L260.444 78.33C260.979 78.5448 261.126 78.8908 261.161 79.3914L261.329 83.4653C261.346 84.0293 261.09 84.389 260.696 84.2627L259.225 83.6667C258.479 83.3625 258.623 83.1999 258.561 82.6072L258.439 78.4966C258.405 77.8301 258.655 77.688 259.069 77.793L259.069 77.7934Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M25.2587 41.9265C29.6595 42.3843 34.5848 41.3064 40.005 38.5374L35.635 45.0509L25.2587 41.9265V41.9265Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M25.2587 41.9261L35.5814 45.0765H35.5818"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M203.074 102.784L221.188 112.874C221.448 113.105 221.805 112.906 222.141 112.521M223.032 110.817C223.139 110.399 223.131 110.05 222.934 109.918L204.374 100.199L203.074 102.784L204.374 100.199"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M221.576 112.235C221.459 112.03 222.101 110.463 222.575 110.573L229.173 114.104C228.714 114.464 228.329 115.278 228.393 115.693L221.576 112.235"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M230.705 115.103L230.62 115.077L229.521 114.595L229.534 114.251L229.173 114.104L229.156 114.149C228.833 114.284 228.289 115.231 228.393 115.686L228.669 115.846L228.99 115.848L229.903 116.493C230.161 116.798 230.438 116.988 230.734 117.073C230.75 116.729 230.727 116.379 230.687 116.027L230.705 115.103V115.103Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M228.669 115.846C228.753 115.076 229.051 114.555 229.534 114.251"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M229.914 115.256L230.531 115.534C230.589 115.567 230.643 115.599 230.695 115.632L230.687 116.027C230.699 116.13 230.709 116.234 230.718 116.337L229.914 115.255V115.256Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M221.188 112.874C220.61 112.204 221.763 109.581 222.934 109.917"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M238.247 101.379C238.382 101.355 238.608 101.382 238.808 101.517C239.441 101.992 240.106 103.022 240.14 104.035C240.132 104.232 240.078 104.347 239.99 104.408L241.731 103.521C242.566 103.038 241.277 100.172 240.34 100.379L238.192 101.391L238.246 101.379H238.247Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M238.177 101.399L238.364 101.31M237.731 101.865C237.902 101.424 238.335 101.198 238.808 101.517C239.441 101.992 240.106 103.022 240.14 104.035C240.108 104.758 239.387 104.367 239.387 104.367L239.607 104.225C239.965 103.717 239.102 101.649 237.964 101.791L237.731 101.866L237.731 101.865Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M236.86 105.995L239.605 104.228C239.962 103.72 239.1 101.653 237.962 101.794"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M148.609 125.015L149.117 125.297"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M158.898 140.237C161.09 140.237 162.867 136.929 162.867 132.848C162.867 128.768 161.09 125.46 158.898 125.46C156.706 125.46 154.929 128.768 154.929 132.848C154.929 136.929 156.706 140.237 158.898 140.237Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M159.153 137.111C160.418 137.111 161.443 135.202 161.443 132.848C161.443 130.494 160.418 128.585 159.153 128.585C157.889 128.585 156.864 130.494 156.864 132.848C156.864 135.202 157.889 137.111 159.153 137.111Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M158.898 140.237C161.09 140.237 162.867 136.929 162.867 132.848C162.867 128.768 161.09 125.46 158.898 125.46C156.706 125.46 154.929 128.768 154.929 132.848C154.929 136.929 156.706 140.237 158.898 140.237Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M159.153 137.111C160.418 137.111 161.443 135.202 161.443 132.848C161.443 130.494 160.418 128.585 159.153 128.585C157.889 128.585 156.864 130.494 156.864 132.848C156.864 135.202 157.889 137.111 159.153 137.111Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M156.227 140.215C154.035 140.215 152.263 136.897 152.258 132.827C152.253 128.756 153.953 125.508 156.138 125.417L158.76 125.464C156.632 125.599 154.929 128.853 154.929 132.848C154.929 136.725 156.533 139.904 158.573 140.212L156.227 140.215V140.215Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M150.562 140.35C148.37 140.35 146.593 137.042 146.593 132.961C146.593 129.714 147.737 126.95 149.301 125.958C149.521 126.444 149.861 126.776 150.181 126.856L150.226 126.867L150.246 127.155L150.437 127.482L150.436 127.483L150.425 127.464L150.418 127.474C149.616 128.826 149.112 130.778 149.111 132.948C149.108 136.972 150.842 140.249 152.994 140.335L153.079 140.337L150.561 140.349L150.562 140.35ZM153.08 140.337H153.097H153.08Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M150.418 127.475L150.425 127.464L151.813 129.752C151.356 130.531 151.046 131.704 151.046 132.949C151.046 135.105 151.906 136.887 153.021 137.172C153.345 137.998 153.758 138.696 154.234 139.214C154.388 139.39 154.547 139.549 154.713 139.686C154.215 140.104 153.663 140.338 153.08 140.338C150.888 140.338 149.109 137.027 149.111 132.949C149.112 130.78 149.617 128.827 150.418 127.476V127.475Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M155.509 104.815C155.655 104.438 156.029 104.24 156.434 104.513C156.974 104.918 157.542 105.797 157.572 106.663C157.544 107.28 156.929 106.946 156.929 106.946L157.113 106.826C157.419 106.392 156.683 104.628 155.711 104.749L155.509 104.815V104.815Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M149.521 117.832C149.649 118.969 149.602 120.083 148.649 121.078C147.978 121.401 147.637 121.918 147.791 122.727L147.924 124.116C147.92 124.506 148.08 124.735 148.342 124.863L148.657 125.04C148.58 124.523 148.503 124.006 148.426 123.49C148.424 122.926 148.642 122.826 149.014 123.048L150.98 124.411L151.017 124.426C151.277 124.661 151.576 124.852 151.583 125.369L151.644 126.24C151.615 126.857 151.863 127.293 152.47 127.471C152.56 127.607 152.69 127.688 152.855 127.697C153.085 127.672 153.291 127.581 153.473 127.453C154.118 126.303 154.977 125.56 155.941 125.435L155.943 125.275L155.941 116.511L155.942 107.582L157.114 106.828C157.419 106.394 156.683 104.629 155.711 104.75L152.722 105.706C151.056 106.206 149.979 107.127 149.697 108.615L149.521 117.833L149.521 117.832Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M151.401 120.676C151.238 121.134 151.222 121.528 151.282 122.117L151.927 124.443L152.327 127.035C152.348 127.205 152.393 127.349 152.463 127.459"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M154.771 108.336L157.114 106.828C157.419 106.394 156.683 104.628 155.711 104.749"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M148.586 124.999L149.093 125.282"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M150.481 126.577C150.808 126.384 150.864 125.752 150.605 125.164C150.347 124.576 149.872 124.255 149.544 124.447C149.217 124.639 149.161 125.272 149.42 125.86C149.678 126.448 150.153 126.769 150.481 126.577Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M149.443 124.216C149.841 123.981 150.419 124.372 150.734 125.088C151.049 125.804 150.981 126.574 150.583 126.808C150.49 126.862 150.388 126.883 150.281 126.874C150.249 126.871 150.215 126.865 150.182 126.856C149.857 126.774 149.511 126.434 149.291 125.936C148.977 125.22 149.044 124.45 149.443 124.216Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M149.443 124.216L150.17 123.849"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M153.42 119.762C153.507 121.067 153.756 122.203 154.553 122.798C155.163 123.151 155.029 123.521 154.902 123.891L154.033 126.623"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M153.42 119.762L153.405 112.321"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M155.935 114.057L157.297 113.366C157.579 113.69 157.88 113.857 158.099 113.663L173.947 104.826L171.058 103.62L156.629 111.175C156.463 111.286 156.456 111.58 156.547 111.932L155.92 112.267M157.704 103.564C158.493 103.39 159.605 105.827 158.901 106.234L157.404 107.001C157.216 107.102 156.929 106.947 156.929 106.947L157.114 106.828C157.415 106.399 156.669 104.63 155.711 104.749L155.509 104.815C155.583 104.622 155.708 104.475 155.874 104.419L157.704 103.564Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M155.94 112.263L155.918 112.274M155.918 114.072L155.926 114.068"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M157.753 113.141C157.852 112.968 157.312 111.649 156.912 111.742L155.94 112.263M155.926 114.068L157.753 113.141"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M158.081 113.679C158.567 113.115 157.596 110.907 156.611 111.19"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M49.949 68.8866L50.4561 69.1693"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M51.9221 70.6807L52.9594 70.1527"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M51.7571 71.3215L53.2626 73.8057L53.1842 77.1657C53.1778 77.6689 53.4187 77.9383 53.6511 78.1178"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M51.902 84.2221C49.7102 84.2221 47.9334 80.9143 47.9334 76.8339C47.9334 73.5864 49.0768 70.822 50.641 69.8308C50.86 70.3164 51.2013 70.648 51.5211 70.7293V70.7289V70.7293L51.566 70.739L51.5858 71.0274L51.7764 71.3547C50.9751 72.7063 50.468 74.6636 50.4665 76.8335C50.464 80.9114 52.2451 84.2095 54.4369 84.2095L51.902 84.2221V84.2221Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M51.7761 71.355L53.0867 73.5158C52.6415 74.3024 52.3501 75.4836 52.3501 76.7172C52.3501 77.4089 52.4386 78.062 52.5958 78.64C52.8957 79.8463 53.4967 80.7005 54.2474 80.9174L54.2488 80.9178L54.2495 80.9181L54.3002 80.9332C54.739 82.0921 55.3519 83.0067 56.068 83.5426L56.0701 83.5444C55.577 83.9591 55.0127 84.2048 54.4365 84.2094H54.4196L54.3344 84.2073C52.1901 84.1062 50.4637 80.8473 50.4666 76.8334C50.468 74.6639 50.9751 72.7066 51.7761 71.355V71.355Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M49.9257 68.8712L50.4324 69.1543"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M50.8841 68.3194C51.2114 68.127 51.6865 68.4478 51.9451 69.0358C52.2037 69.6239 52.148 70.2566 51.8203 70.449C51.493 70.6414 51.0183 70.3206 50.7593 69.7325C50.5007 69.1445 50.5568 68.5118 50.8841 68.3194Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M50.7823 68.0882C51.1808 67.8537 51.7588 68.2447 52.0739 68.9604C52.3882 69.6761 52.3206 70.4462 51.9221 70.6803C51.8297 70.7346 51.7272 70.7555 51.6203 70.7461C51.588 70.7433 51.5545 70.7375 51.5214 70.7292V70.7289V70.7292C51.1967 70.6469 50.8499 70.3063 50.6309 69.8081C50.3162 69.0924 50.3842 68.322 50.7823 68.0882Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M50.7823 68.0882L51.5103 67.7217"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M60.2141 69.3164C62.4059 69.3164 64.1831 72.6246 64.1831 76.705C64.1831 80.7858 62.4059 84.094 60.2141 84.094C58.022 84.094 56.2452 80.7858 56.2452 76.705C56.2452 72.6246 58.022 69.3164 60.2141 69.3164Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M60.4692 72.4426C61.7338 72.4426 62.7592 74.351 62.7592 76.705C62.7592 79.0597 61.7338 80.9678 60.4692 80.9678C59.2046 80.9678 58.1796 79.0594 58.1796 76.705C58.1796 74.3506 59.2046 72.4426 60.4692 72.4426Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M60.2141 69.3164C62.4059 69.3164 64.1831 72.6246 64.1831 76.705C64.1831 80.7858 62.4059 84.094 60.2141 84.094C58.022 84.094 56.2452 80.7858 56.2452 76.705C56.2452 72.6246 58.022 69.3164 60.2141 69.3164Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M57.5434 84.0721C55.3516 84.0721 53.5799 80.7539 53.5745 76.6835C53.5694 72.6132 55.3577 69.3856 57.5434 69.2949L60.0765 69.3215C57.9484 69.4568 56.2457 72.7107 56.2457 76.7055C56.2457 80.5819 57.8495 83.761 59.8891 84.0693L57.5434 84.0721Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M50.837 61.689C50.9658 62.8259 50.9187 63.9398 49.9652 64.9357C49.2941 65.2579 48.9538 65.7751 49.1078 66.5844L49.2401 67.9727C49.2362 68.3626 49.3966 68.5917 49.6584 68.7201L49.9731 68.8967C49.8962 68.3802 49.8192 67.8634 49.7422 67.3469C49.7397 66.7826 49.9584 66.6833 50.3303 66.9048L52.2958 68.268L52.334 68.2831C52.5933 68.5179 52.8929 68.7093 52.8994 69.2265L52.9609 70.0972C52.9317 70.7144 53.1799 71.1496 53.787 71.3284C53.8769 71.4643 54.0061 71.5453 54.1719 71.5546C54.4423 71.524 54.679 71.4039 54.8847 71.2385H54.8851L54.9045 71.2068L54.9236 71.1755L54.9433 71.1442L54.9473 71.1378L54.9628 71.1133L54.9825 71.0827L55.0023 71.0522L55.0203 71.0241L55.0218 71.0219L55.0419 70.9917L55.062 70.9619L55.0822 70.9324L55.0951 70.9133L55.1023 70.9029L55.1228 70.8738L55.1433 70.8446L55.1638 70.8159L55.171 70.8058L55.1847 70.7878L55.2055 70.7594L55.2264 70.7313L55.2473 70.7033L55.2487 70.7015L55.2685 70.6759L55.3109 70.6216L55.3275 70.6004L55.3321 70.5947L55.3537 70.568L55.3753 70.5414L55.3969 70.5152L55.4073 70.5022L55.4181 70.4893L55.44 70.4637L55.462 70.4386L55.4839 70.4137L55.4886 70.408L55.5059 70.3886L55.5282 70.3641L55.5505 70.3397L55.5713 70.3174L55.5728 70.3159L55.5749 70.3138L55.6177 70.2684L55.6404 70.2451L55.6548 70.23L55.663 70.222L55.6857 70.1994L55.7087 70.1767L55.7314 70.1544L55.7396 70.1462L55.7544 70.1325L55.7778 70.1105L55.8008 70.089L55.8242 70.0677L55.8443 70.0498L55.8475 70.0469L55.8709 70.026L55.8943 70.0055L55.9134 69.9893L55.9184 69.9854L55.9421 69.9652L55.9659 69.9455L55.9896 69.926L56.0018 69.916L56.0137 69.907L56.0378 69.8879L56.0619 69.8696L56.086 69.8516L56.1101 69.8336L56.1342 69.816L56.1587 69.7987L56.1817 69.7822L56.1831 69.7815L56.2076 69.7649L56.232 69.7484L56.2568 69.7318L56.2727 69.7214L56.2813 69.716L56.3061 69.7002C56.5938 69.5056 56.9111 69.3617 57.2578 69.2966L57.2599 69.1337L57.2581 58.9415L57.0136 59.2141L56.7079 58.8063L51.0075 55.2506L50.837 61.6911V61.689Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M52.7169 64.5328C52.554 64.9914 52.5389 65.3852 52.5982 65.9744L53.2438 68.3003L53.6434 70.8917C53.6639 71.0618 53.7096 71.2061 53.7794 71.3161"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M54.7599 63.6348C54.8469 64.9396 55.0951 66.0762 55.8928 66.67C56.5028 67.0239 56.3687 67.3933 56.2414 67.763L55.3487 70.5713"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M54.7599 63.6348L54.748 57.582"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M40.0028 38.5399L54.5689 47.6017L54.4667 51.4717L60.5778 55.2403L57.0132 59.2121L56.7074 58.8042L35.3196 45.4631L35.5814 45.0768H35.5818H35.5814V45.0765L40.0028 38.5399Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M86.3173 4.08899L101.707 8.73659L106.89 10.3019C112.419 12.3555 110.918 19.5524 104.242 17.0233L101.798 16.4054L78.3428 10.4727L86.3173 4.08899V4.08899Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M288.8 112.827L292.427 114.671L291.981 115.949L288.472 114.255L288.8 112.827Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M306.805 132.576L306.032 129.736L304.372 130.362L304.195 130.384L300.389 131.098L299.599 132.934L306.805 132.576Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M280.615 132.272L280.701 131.26L282.375 131.284L282.182 132.573L280.615 132.272Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M272.741 131.397L272.827 130.384L274.501 130.408L274.308 131.698L272.741 131.397Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M136.351 91.3988C136.299 91.1841 136.268 90.992 136.232 90.7744L135.162 87.5932L136.529 87.504L136.648 87.4742L137.659 86.493L134.448 83.639C134.298 83.639 134.365 83.6466 134.21 83.6984L131.178 84.9471L133.021 86.5825V86.7311L134.478 91.0719L136.351 91.3988V91.3988Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M112.195 51.9207C112.782 52.0188 113.408 51.2025 113.592 50.0976C113.777 48.9927 113.451 48.0174 112.864 47.9194C112.277 47.8213 111.651 48.6376 111.467 49.7425C111.282 50.8474 111.608 51.8227 112.195 51.9207Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M108.193 26.1775C108.589 26.1718 108.902 25.5623 108.891 24.8161C108.881 24.0699 108.551 23.4695 108.154 23.4751C107.758 23.4808 107.445 24.0903 107.456 24.8365C107.466 25.5827 107.796 26.1831 108.193 26.1775Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M134.19 80.9564C134.164 80.5961 133.651 80.3395 133.046 80.3833C132.44 80.427 131.969 80.7546 131.995 81.1149C132.022 81.4753 132.534 81.7319 133.14 81.6881C133.746 81.6443 134.216 81.3168 134.19 80.9564Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M129.424 82.8806C129.398 82.5203 128.885 82.2637 128.279 82.3075C127.673 82.3512 127.203 82.6788 127.229 83.0391C127.255 83.3995 127.768 83.6561 128.374 83.6123C128.98 83.5685 129.45 83.241 129.424 82.8806Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M106.221 19.3848L104.845 21.2759C104.743 21.3727 104.77 21.546 104.375 21.4435L104.244 21.4129L102.401 20.9306L102.336 20.9173L101.975 20.74L101.026 20.5256L100.632 20.3458L100.634 20.3214L100.665 20.1408L100.708 20.1192L101.096 20.2009L102.009 20.3933L102.435 20.3764L103.487 20.6483C103.693 20.7022 103.804 20.5559 103.872 20.3911L104.655 19.0906L104.721 19.0956L106.221 19.3851L106.221 19.3848Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M101.042 20.5156L101.048 20.4911L101.112 20.1904"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M101.991 20.7295L102.026 20.3828"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M102.352 20.9069L102.452 20.366"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M103.6 20.6825L104.533 20.8911L104.549 20.8882L104.708 21.0414"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M57.1646 19.455L55.7889 21.3457C55.6864 21.4425 55.7134 21.6158 55.3185 21.5137L55.1879 21.4831L53.3446 21.0008L53.2792 20.9871L52.9184 20.8098L51.9696 20.5958L51.5758 20.416L51.5776 20.3912L51.6078 20.2106L51.6513 20.189L52.0391 20.2707L52.953 20.4635L53.3784 20.4462L54.4308 20.7181C54.6369 20.7721 54.7477 20.626 54.8153 20.4613L55.5979 19.1604L55.6641 19.1658L57.1643 19.4553L57.1646 19.455Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M51.9858 20.5858L51.992 20.5613L52.0556 20.2603"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M52.9342 20.7997L52.9691 20.453"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M53.295 20.9771L53.3947 20.4358"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M54.5435 20.7527L55.4761 20.9613L55.4926 20.9584L55.6512 21.112"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M82.1905 30.7405C82.2495 30.3513 82.1729 29.9791 81.9405 29.7266C81.4586 29.2011 80.6802 29.5763 79.9944 30.2064C79.3088 30.8362 78.997 31.9152 79.4808 32.4417C79.9163 32.9161 80.6849 32.6359 81.3359 32.1126C81.0698 32.1673 80.8083 32.1651 80.554 32.0821L79.8631 31.4311C80.053 31.0405 80.5518 30.847 81.2129 30.7563L82.1908 30.7409L82.1905 30.7405Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M80.554 32.0813L79.863 31.4303C80.0529 31.0397 80.5518 30.8462 81.2129 30.7556L82.2329 30.7394L82.8436 31.3659C82.5318 31.5788 82.2214 31.7723 81.9153 31.9137L81.6671 31.6828C81.5567 31.6634 81.4679 31.7148 81.4538 31.8903L81.6196 32.0331C81.2553 32.1586 80.8985 32.1939 80.554 32.0809V32.0813Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M80.554 32.0813L80.5939 32.0249C80.9719 31.5746 81.8434 31.4307 82.8436 31.366"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M81.4539 31.8907C81.4679 31.7152 81.5567 31.6638 81.6671 31.6832L82.1286 32.1119C82.2786 32.322 82.1448 32.4572 81.9437 32.3126L81.4539 31.8907Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M229.776 123.209L230.283 123.492"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M240.064 138.432C242.256 138.432 244.033 135.124 244.033 131.043C244.033 126.963 242.256 123.655 240.064 123.655C237.872 123.655 236.095 126.963 236.095 131.043C236.095 135.124 237.872 138.432 240.064 138.432Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M240.32 135.306C241.584 135.306 242.609 133.398 242.609 131.043C242.609 128.689 241.584 126.781 240.32 126.781C239.055 126.781 238.03 128.689 238.03 131.043C238.03 133.398 239.055 135.306 240.32 135.306Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M240.064 138.432C242.256 138.432 244.033 135.124 244.033 131.043C244.033 126.963 242.256 123.655 240.064 123.655C237.872 123.655 236.095 126.963 236.095 131.043C236.095 135.124 237.872 138.432 240.064 138.432Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M240.32 135.306C241.584 135.306 242.609 133.398 242.609 131.043C242.609 128.689 241.584 126.781 240.32 126.781C239.055 126.781 238.03 128.689 238.03 131.043C238.03 133.398 239.055 135.306 240.32 135.306Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M237.393 138.41C235.202 138.41 233.429 135.092 233.424 131.022C233.419 126.951 235.119 123.704 237.305 123.613L239.926 123.659C237.798 123.795 236.096 127.049 236.096 131.043C236.096 134.92 237.699 138.099 239.739 138.407L237.393 138.41V138.41Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M231.729 138.545C229.537 138.545 227.76 135.237 227.76 131.156C227.76 127.91 228.903 125.145 230.467 124.154C230.687 124.639 231.028 124.971 231.348 125.052L231.393 125.062L231.412 125.35L231.603 125.677L231.603 125.678L231.591 125.659L231.584 125.67C230.783 127.021 230.278 128.973 230.277 131.143C230.275 135.168 232.008 138.445 234.161 138.53L234.246 138.532L231.728 138.545L231.729 138.545ZM234.247 138.532H234.263H234.247Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M231.584 125.67L231.591 125.659L232.979 127.947C232.522 128.726 232.212 129.899 232.212 131.144C232.212 133.3 233.072 135.082 234.187 135.367C234.511 136.193 234.924 136.891 235.4 137.409C235.554 137.585 235.713 137.744 235.879 137.881C235.381 138.299 234.829 138.533 234.246 138.533C232.054 138.533 230.275 135.222 230.277 131.144C230.278 128.975 230.783 127.022 231.584 125.671V125.67Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M230.687 116.027C230.816 117.164 230.769 118.278 229.815 119.274C229.144 119.596 228.804 120.113 228.958 120.923L229.09 122.311C229.086 122.701 229.247 122.93 229.508 123.058L229.823 123.235C229.746 122.718 229.669 122.202 229.592 121.685C229.59 121.121 229.808 121.022 230.18 121.243L232.146 122.606L232.184 122.621C232.443 122.856 232.743 123.048 232.749 123.564L232.811 124.435C232.782 125.052 233.03 125.488 233.637 125.666C233.727 125.803 233.856 125.884 234.022 125.893C234.252 125.867 234.457 125.776 234.639 125.648C235.285 124.498 236.143 123.755 237.107 123.631L237.11 123.471L237.108 114.706L237.108 105.778L239.61 104.221C240 103.777 238.977 101.3 237.731 101.866L233.889 103.901C232.222 104.401 231.146 105.322 230.863 106.81L230.687 116.028L230.687 116.027Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M232.567 118.871C232.404 119.329 232.389 119.723 232.448 120.312L233.094 122.638L233.494 125.23C233.514 125.4 233.56 125.544 233.629 125.654"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M229.752 123.194L230.259 123.477"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M231.647 124.772C231.974 124.579 232.03 123.947 231.771 123.359C231.513 122.77 231.038 122.45 230.711 122.642C230.383 122.834 230.327 123.467 230.586 124.055C230.844 124.643 231.319 124.964 231.647 124.772Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M230.609 122.411C231.007 122.176 231.585 122.567 231.9 123.283C232.215 123.999 232.147 124.769 231.749 125.003C231.656 125.057 231.554 125.078 231.447 125.069C231.415 125.066 231.381 125.06 231.348 125.051C231.023 124.969 230.677 124.629 230.457 124.131C230.143 123.415 230.21 122.644 230.609 122.411V122.411Z"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M230.609 122.411L231.337 122.044"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M234.587 117.957C234.674 119.262 234.922 120.398 235.719 120.992C236.329 121.346 236.195 121.715 236.068 122.086L235.199 124.818"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
                <path
                    d="M234.587 117.957L234.571 110.516"
                    stroke={theme.palette.mode === 'light' ? '#C4D8F8' : '#5992F1'}
                    strokeMiterlimit="22.9256"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_624_1178">
                    <rect width="446.23" height="733.16" fill="white" transform="translate(0 102)" />
                </clipPath>
                <clipPath id="clip1_624_1178">
                    <rect width="431.546" height="140.494" fill="white" transform="translate(17)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default FlightVertical
