import React from 'react'

import { UserCtxType, UserSetterCtxType } from './types'

export const userCtx = React.createContext<UserCtxType>(null)
export const userSetterCtx = React.createContext<UserSetterCtxType>(() => null)

const UserContext = ({ children }: { children?: React.ReactNode }) => {
    const [user, setUser] = React.useState<UserCtxType>(null)

    return (
        <userCtx.Provider value={user}>
            <userSetterCtx.Provider value={setUser}>{children}</userSetterCtx.Provider>
        </userCtx.Provider>
    )
}

export default UserContext
