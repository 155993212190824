import React from 'react'
import './App.css'

import UserContext from './context/user/userContext'
import MyApp from './components/MyApp'
import NotificationsContext from './context/notifications/notificationsContext'
import Compose from './context/compose'
import ThemeContext from './context/theme/theme'
import FormContext from './context/form/formContext'
import ModalContext from './context/modal/modalContext'
import RunwayInfoContext from './context/runwayInfo/runwayInfoContext'
import FormResultContext from './context/formResult/formResultContext'
import MalfunctionsContext from './context/malfunctions/malfunctions'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fontsource/roboto-mono/400.css'
import '@fontsource/roboto-condensed/700.css'
import RunwayOptionsContext from './context/runwayOptions/runwayOptions'
import InstallPwaPrompt from './components/InstallPwaPrompt'

const MIN_HEIGHT_PX = 700 // minimum window height to fit content with keyboard closed

const isStandalone = window.matchMedia('(display-mode: standalone)').matches || process.env.NODE_ENV !== 'production'

function App() {
    React.useEffect(() => {
        const preventPwaWindowScroll = (e: any) => {
            e.preventDefault()
            if (window.innerHeight >= MIN_HEIGHT_PX) {
                window.scrollTo(0, 0)
            }
        }
        if (isStandalone) {
            window.addEventListener('scroll', preventPwaWindowScroll)
        }

        return () => {
            if (isStandalone) {
                window.removeEventListener('scroll', preventPwaWindowScroll)
            }
        }
    }, [])

    return (
        <>
            {isStandalone ? (
                <Compose
                    components={[
                        UserContext,
                        NotificationsContext,
                        ThemeContext,
                        FormContext,
                        ModalContext,
                        RunwayInfoContext,
                        FormResultContext,
                        MalfunctionsContext,
                        RunwayOptionsContext,
                    ]}
                >
                    <div className="App">
                        <MyApp />
                    </div>
                </Compose>
            ) : (
                <InstallPwaPrompt />
            )}
        </>
    )
}

export default App
