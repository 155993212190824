import React from 'react'
import { Alert, AlertColor, Snackbar } from '@mui/material'
import { notificationCtx, notificationSetterCtx } from '../../context/notifications/notificationsContext'
import { NotificationTypes } from '../../context/notifications/types'

const COLOR_HANDBOOK: Record<NotificationTypes, AlertColor> = {
    [NotificationTypes.ERROR]: 'error',
    [NotificationTypes.SUCCESS]: 'success',
    [NotificationTypes.INFO]: 'info',
}

const Notification = () => {
    const notification = React.useContext(notificationCtx)
    const notificationSetter = React.useContext(notificationSetterCtx)

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }
        notificationSetter(null)
    }

    const onClose = () => {
        notificationSetter(null)
    }

    return (
        <>
            {notification && (
                <Snackbar
                    open={true}
                    autoHideDuration={notification.type === NotificationTypes.ERROR ? 4000 : 3000}
                    message="Note archived"
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    onClose={onClose}
                >
                    <Alert
                        onClose={handleClose}
                        severity={COLOR_HANDBOOK[notification.type] || 'info'}
                        style={{ whiteSpace: 'pre-wrap' }}
                    >
                        {notification?.message}
                    </Alert>
                </Snackbar>
            )}
        </>
    )
}

const NotificationMemoized = React.memo(Notification)
export { NotificationMemoized as Notification }
