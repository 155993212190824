/** @jsxImportSource @emotion/react */
import { InputBase } from '@mui/material'
import { InputBaseProps } from '@mui/material/InputBase/InputBase'
import { useStyles } from './useStyles'

export const MyInput = (props: InputBaseProps) => {
    const classes = useStyles()

    return <InputBase {...props} css={classes.input} />
}
