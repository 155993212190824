/** @jsxImportSource @emotion/react */
import React from 'react'

import { useStyles } from './useStyles'

const GroupWrapper = React.memo(({ children }: { children: React.ReactNode }) => {
    const classes = useStyles()

    return <div css={classes.groupWrapper}>{children}</div>
})

export default GroupWrapper
