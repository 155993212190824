import React from 'react'
import { css } from '@emotion/react'
import { themeCtx } from '../../../context/theme/theme'
import { useTheme } from '@mui/material'

export const useStyles = () => {
    const themeMode = React.useContext(themeCtx)
    const theme = useTheme()

    return React.useMemo(
        () => ({
            hidden: css`
                display: none;
            `,
            applyButtonWrapper: css`
                width: calc((100vw - ${theme.spacing(2)}*2) * 0.48);
                height: calc((100vw - ${theme.spacing(2)}*2) * 0.48);

                display: flex;
                align-items: center;
                justify-content: center;

                @media (min-width: 1023px) {
                    width: calc((100vw - ${theme.spacing(2)}*2) * 0.3);
                    height: calc((100vw - ${theme.spacing(2)}*2) * 0.3);
                }

                @media (max-height: 1024px) and (max-width: 768px) {
                    width: calc((100vw - ${theme.spacing(1)}*2) * 0.48);
                    height: calc((100vw - ${theme.spacing(1)}*2) * 0.48);
                }
            `,
            applyButton: css`
                width: 40%;
                height: 40%;
                border-radius: 50%;
                &.Mui-disabled {
                    background-color: ${themeMode === 'dark' ? '#4B4B4B' : '#D3D3D3'};
                    color: ${themeMode === 'dark' ? '#818181' : theme.palette.secondary.dark};
                }
            `,
        }),
        [themeMode, theme],
    )
}
