/** @jsxImportSource @emotion/react */
import React from 'react'
import { MenuItem, Select } from '@mui/material'

import { useStyles } from './useStyle'
import { MyInput } from '../MyInput/MyInput'

export interface IMySelectOption<T> {
    value: T
    label: string
}

interface IMySelectProps<T> {
    options: IMySelectOption<T>[]
    disabled?: boolean
    value: T
    setValue: (arg: T) => void
    name: string
    error: boolean
    className?: string
}

const MySelect = <T extends number | string>({
    options,
    disabled = false,
    value,
    setValue,
    name,
    error,
}: IMySelectProps<T>) => {
    const classes = useStyles()

    return (
        <Select
            id={name}
            name={name}
            variant="standard"
            value={value}
            onChange={(e) => setValue(e.target.value as T)}
            disabled={disabled}
            input={<MyInput error={error} />}
            css={classes.select}
        >
            {options.map((el) => (
                <MenuItem key={el.value} value={el.value} css={classes.menuItem}>
                    {el.label}
                </MenuItem>
            ))}
        </Select>
    )
}

export default React.memo(MySelect) as typeof MySelect
