import { css } from '@emotion/react'

export const styles = {
    wrapper: css`
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    `,
    logoWrapper: css`
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    `,
    airImage: css`
        position: absolute;
        right: -40%;
        top: -200px;
        @media (min-width: 824px) {
            display: none;
        }
    `,
}
