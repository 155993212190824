import React from 'react'

import { FormResultCtxType, FormResultSetterCtxType } from './types'
import { formCtx } from '../form/formContext'

const initialFormResultCtx: FormResultCtxType = {
    status: '',
    result: {
        status: 'OK',
        error_text: null,
        max_allowed_tow: 0,
        limit_codes: null,
        normal: null,
        flex: null,
    },
}

export const formResultCtx = React.createContext<FormResultCtxType>(initialFormResultCtx)
export const formResultSetterCtx = React.createContext<FormResultSetterCtxType>(() => null)

const NotificationContext = ({ children }: { children?: React.ReactNode }) => {
    const [formResult, setFormResult] = React.useState<FormResultCtxType>(initialFormResultCtx)
    const formData = React.useContext(formCtx)

    React.useEffect(() => {
        setFormResult(initialFormResultCtx)
    }, [formData])

    return (
        <formResultCtx.Provider value={formResult}>
            <formResultSetterCtx.Provider value={setFormResult}>{children}</formResultSetterCtx.Provider>
        </formResultCtx.Provider>
    )
}

export default NotificationContext
