import { css } from '@emotion/react'

export const useStyles = () => {
    return {
        wrapper: css`
            display: flex;
            align-items: center;
        `,
        radioRow: css`
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        `,
        radioHalfWidth: css`
            width: 50%;
        `,
        radioFullWidth: css`
            width: 100%;
        `,
    }
}
