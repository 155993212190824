import { useCallback, useState } from 'react'

export default () => {
    const [isLoading, setIsLoading] = useState(false)

    const toggleIsLoading = useCallback(() => {
        setIsLoading((prev) => !prev)
    }, [])

    return {
        setIsLoading,
        isLoading,
        toggleIsLoading,
    }
}
