/** @jsxImportSource @emotion/react */
import * as React from 'react'
import Dialog from '@mui/material/Dialog'

import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { modalSetterCtx } from '../../../context/modal/modalContext'
import { useStyles } from './useStyles'

const AlertModal = ({ title, message }: { title: string; message: string }) => {
    const hideModal = React.useContext(modalSetterCtx).hideModal
    const classes = useStyles()

    return React.useMemo(
        () => (
            <Dialog open={true}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" css={classes.message}>
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" variant="contained" onClick={hideModal}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        ),
        [title, message, hideModal, classes],
    )
}

export default React.memo(AlertModal)
