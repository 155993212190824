/** @jsxImportSource @emotion/react */
import React from 'react'

import GroupTitle from '../GroupTitle/GroupTitle'
import AirportAutocomplete from './AirportAutocomplete'
import RunwaySelect from './RunwaySelect'
import RunwayInfo from './RunwayInfo/RunwayInfo'
import GroupWrapper from '../GroupWrapper/GroupWrapper'

const RunwayData = () => {
    return (
        <GroupWrapper>
            <GroupTitle title="Runway data" />
            <AirportAutocomplete />
            <RunwaySelect />
            <RunwayInfo />
        </GroupWrapper>
    )
}

export default React.memo(RunwayData)
