/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react'
import { Autocomplete, AutocompleteInputChangeReason, TextField, Typography } from '@mui/material'

import useThrottle from '../../../../hooks/useThrottle'
import useIsLoading from '../../../../hooks/useIsLoading'

import useStyles from './useStyles'
import { IAirport, IAirportOption } from './types'
import { runwayOptionsSetterCtx } from '../../../../context/runwayOptions/runwayOptions'
import { formCtx, formSetterCtx, initialFormCtxData } from '../../../../context/form/formContext'
import { initialRunwayInfoCtx, runwayInfoSetterCtx } from '../../../../context/runwayInfo/runwayInfoContext'
import useFetch from '../../../../hooks/useFetch'
import { responseToNotificationMessage } from '../../../../hooks/utils'
import { notificationSetterCtx } from '../../../../context/notifications/notificationsContext'
import { NotificationTypes } from '../../../../context/notifications/types'

const AirportAutocomplete = () => {
    const throttleWrapper = useThrottle(500)
    const classes = useStyles()
    const [airportOptions, setAirportOptions] = React.useState<IAirportOption[] | null>(null)
    const { isLoading, setIsLoading } = useIsLoading()
    const runwayOptionsSetter = React.useContext(runwayOptionsSetterCtx)
    const formSetter = React.useContext(formSetterCtx)
    const setRunwayData = React.useContext(runwayInfoSetterCtx)
    const fetcher = useFetch()
    const { airport } = useContext(formCtx)
    const [inputValue, setInputValue] = React.useState('')
    const [currentOption, setCurrentOption] = React.useState<IAirportOption | null>(null)
    const notificationSetter = React.useContext(notificationSetterCtx)

    const setAirportOptionsWithCurrent = React.useCallback(
        (value: IAirportOption[] | null) => {
            let newOptions: IAirportOption[] = value ? value : []
            if (currentOption && !newOptions.find((el) => el.id === currentOption.id)) {
                newOptions.push(currentOption)
            }
            return setAirportOptions(newOptions)
        },
        [currentOption],
    )

    React.useEffect(() => {
        if (airport.value === null) {
            setCurrentOption(null)
        }
    }, [airport.value])

    const onChangeTextField = React.useCallback(
        (_: any, value: string, reason: AutocompleteInputChangeReason) => {
            setInputValue(value)
            if (reason !== 'input') {
                return
            }

            const valid_query = new RegExp(/^[A-Z]{2,4}$/i)

            if (!valid_query.test(value)) {
                setAirportOptionsWithCurrent(null)
                return
            }

            setIsLoading(true)
            setAirportOptionsWithCurrent(null)

            throttleWrapper(async () => {
                const parameter = value.toLowerCase()
                try {
                    const options: { suggestions: IAirport[] } = await fetcher(`perf/airports?query=${parameter}`, {
                        method: 'GET',
                        credentials: 'include',
                    })
                    const preparedOptions = options.suggestions.map((el) => {
                        return {
                            label: `${el.icao} ${el.name}`,
                            id: el.icao,
                            runways: el.runways,
                        }
                    })
                    setAirportOptionsWithCurrent(preparedOptions)
                    setIsLoading(false)
                } catch (err: any) {
                    setIsLoading(false)
                    if (err instanceof Response) {
                        const message = await responseToNotificationMessage(err)
                        notificationSetter({
                            type: NotificationTypes.ERROR,
                            message: message,
                        })
                    } else {
                        notificationSetter({
                            type: NotificationTypes.ERROR,
                            message: `Something went wrong`,
                        })
                    }
                }
            })
        },
        [setIsLoading, throttleWrapper, fetcher, setAirportOptionsWithCurrent, notificationSetter],
    )

    const onChangeAutocomplete = (_e: any, option: IAirportOption | null) => {
        runwayOptionsSetter(option?.runways || [])
        formSetter((prev) => ({
            ...prev,
            runway_id: initialFormCtxData.runway_id,
            airport: { ...prev.airport, value: option?.id || null },
        }))
        setRunwayData(initialRunwayInfoCtx)
        setCurrentOption(airportOptions?.find((el) => el.id === option?.id) || null)

        if (!option) {
            setAirportOptions(null)
        }
    }

    const onFocus = () => {
        setInputValue('')
        setAirportOptionsWithCurrent([])
    }

    return (
        <Autocomplete
            id="airport_id"
            css={classes.airportsAutocomplete}
            disablePortal
            blurOnSelect={true}
            options={airportOptions || []}
            loading={isLoading}
            value={currentOption}
            inputValue={inputValue}
            onChange={onChangeAutocomplete}
            onFocus={onFocus}
            onInputChange={onChangeTextField}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            filterOptions={(options, state) => {
                return options.filter((option) => option.id.toLowerCase().startsWith(state.inputValue.toLowerCase()))
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    css={[
                        classes.airportsAutocompleteTextField,
                        airport.value === null && classes.airportsAutocompleteTextFieldEmpty,
                    ]}
                    label={`Airport`}
                />
            )}
            renderOption={(props, data) => {
                const [icao, name] = [data.label.slice(0, 4), data.label.slice(5)]

                return (
                    <li {...props} css={classes.option}>
                        <span css={classes.optionIcao}>{icao}</span> {name}
                    </li>
                )
            }}
            noOptionsText={
                <Typography color={'inherit'}>
                    {isLoading
                        ? 'Loading...'
                        : inputValue.length < 2
                          ? 'Start typing (min. 2 characters)'
                          : 'No airports found'}
                </Typography>
            }
        />
    )
}

export default React.memo(AirportAutocomplete)
