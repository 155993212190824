import { useTheme } from '@mui/material'
import { css } from '@emotion/react'

export const useStyles = () => {
    const theme = useTheme()

    return {
        input: css`
            &.Mui-error {
                & .MuiInputBase-input {
                  border: 1px solid ${theme.palette.error.main},
                },
            },
            & .MuiInputBase-input {
              position: relative;
              background-color: inherit;
              padding: 10px 14px;
              -webkit-appearance: none;
              -webkit-box-shadow: 0 2px 2px 0 #0000001A !important;
              box-shadow: 0 2px 2px 0 #0000001A !important;
              border-radius: 4px;
              display: flex;
              align-items: center;
            },
            & .MuiSelect-icon {
              color: ${theme.palette.primary.main};
            },
            & .Mui-disabled {
              color: ${theme.palette.info.main};
            }
        `,
    }
}
