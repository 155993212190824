import React from 'react'
import { useTheme } from '@mui/material'
import { css } from '@emotion/react'

export default () => {
    const theme = useTheme()

    return React.useMemo(
        () => ({
            runwayInfoWrapper: css`
                width: 100%;
                border-radius: 8px;
                border: 1px solid ${theme.palette.primary.main};
                padding: ${theme.spacing(0.5, 2.5)};
                margin-top: ${theme.spacing(1.5)};
            `,
            title: css`
                text-transform: uppercase;
                font-weight: 700;
                font-size: 18px;
                text-align: center;
                color: ${theme.palette.primary.main};
            `,
            infoRowWrapper: css`
                display: flex;
                justify-content: space-between;
                align-items: baseline;
            `,
            infoRowName: css`
                color: ${theme.palette.info.main};
                text-transform: uppercase;
                font-size: 16px;
            `,
            infoRowData: css`
                color: ${theme.palette.primary.main};
                font-size: 16px;
            `,
            dotedLine: css`
                height: 1px;
                flex-grow: 1;
                margin: ${theme.spacing(0, 1)};
                background-image: linear-gradient(
                    to right,
                    ${theme.palette.primary.main} 25%,
                    rgba(255, 255, 255, 0) 0%
                );
                background-position: top;
                background-size: 8px 1px;
                background-repeat: repeat-x;
            `,
        }),
        [theme],
    )
}
