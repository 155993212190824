import React from 'react'

import { FormCtx, FormCtxSetter } from './types'

export const initialMalfunctions = {
    ground_spoilers: { value: 0, error: false },
    anti_skid: { value: 0, error: false },
    thrust_reverse: { value: 1, error: false },
    landing_gear: { value: 0, error: false },
    hydraulic_pumps: { value: 0, error: false },
    nw_steering: { value: 0, error: false },
    apr: { value: 0, error: false },
    ecprv: { value: 0, error: false },
}

export const initialFormCtxData = {
    database: { value: null, error: false },
    airport: { value: null, error: false },
    runway_id: { value: null, error: false },
    surface_condition: { value: 0, error: false },
    oat: { value: null, error: false },
    pressure_altitude: { value: null, error: false },
    wind_component: { value: null, error: false },
    takeoff_procedure: { value: 0, error: false },
    takeoff_weight: { value: null, error: false },
    acu: { value: 2, error: false },
    apu: { value: 1, error: false },
    anti_icing: { value: 0, error: false },
    ...initialMalfunctions,
}

export const formCtx = React.createContext<FormCtx>(initialFormCtxData)
export const formSetterCtx = React.createContext<FormCtxSetter>(() => null)

const FormContext = ({ children }: { children?: React.ReactNode }) => {
    const [form, setForm] = React.useState<FormCtx>(initialFormCtxData)

    return (
        <formCtx.Provider value={form}>
            <formSetterCtx.Provider value={setForm}>{children}</formSetterCtx.Provider>
        </formCtx.Provider>
    )
}

export default FormContext
