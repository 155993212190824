import React from 'react'
import { ThemeCtxType, ThemeToggleCtxType } from './type'

export const themeCtx = React.createContext<ThemeCtxType>('light')
export const themeToggleCtx = React.createContext<ThemeToggleCtxType>(() => null)

const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)')

const ThemeContext = ({ children }: { children?: React.ReactNode }) => {
    const [theme, setTheme] = React.useState<ThemeCtxType>(darkModeQuery.matches ? 'dark' : 'light')

    const themeToggle = React.useCallback(() => {
        setTheme((prev) => {
            const res = prev === 'light' ? 'dark' : 'light'

            return res
        })
    }, [])

    React.useEffect(() => {
        const updateTheme = () => {
            setTheme(darkModeQuery.matches ? 'dark' : 'light')
        }

        darkModeQuery.addEventListener('change', updateTheme)

        return () => {
            darkModeQuery.removeEventListener('change', updateTheme)
        }
    }, [])

    return (
        <themeCtx.Provider value={theme}>
            <themeToggleCtx.Provider value={themeToggle}>{children}</themeToggleCtx.Provider>
        </themeCtx.Provider>
    )
}

export default ThemeContext
