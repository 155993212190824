/** @jsxImportSource @emotion/react */
import React from 'react'

import GroupTitle from '../GroupTitle/GroupTitle'
import { useStyles } from './useStyles'
import MyRadioGroup from '../../../ui/components/MyRadioGroup/MyRadioGroup'
import GroupWrapper from '../GroupWrapper/GroupWrapper'

const ACU_OPTIONS = [
    { value: 2, label: 'Off' },
    { value: 1, label: 'On' },
]

const APU_OPTIONS = [
    { value: 0, label: 'Off' },
    { value: 1, label: 'On' },
]

const ANTI_ICING_OPTIONS = [
    { value: 0, label: 'Off' },
    { value: 1, label: 'Engine' },
    { value: 3, label: 'Engine & Wing' },
]

const AircraftConfiguration = () => {
    const classes = useStyles()

    return (
        <GroupWrapper>
            <GroupTitle title={'Aircraft configuration'} />
            <div css={classes.radioRow}>
                <MyRadioGroup
                    name="acu"
                    options={ACU_OPTIONS}
                    css={classes.radioHalfWidth}
                    label={'Air conditioning'}
                />
                <MyRadioGroup name="apu" options={APU_OPTIONS} css={classes.radioHalfWidth} label={'APU'} />
                <MyRadioGroup
                    name="anti_icing"
                    options={ANTI_ICING_OPTIONS}
                    css={classes.radioFullWidth}
                    label={'Anti-Icing'}
                />
            </div>
        </GroupWrapper>
    )
}

export default React.memo(AircraftConfiguration)
