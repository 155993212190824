import { API_URL } from './constants'

interface IFetcherData {
    input: RequestInfo
    init?: RequestInit
    showError: boolean
    logOut: () => void
}

export default async <T>({ input, init, showError = true, logOut }: IFetcherData) => {
    const response = await fetch(`${API_URL}/${input}`, init)

    if (!response.ok) {
        if (showError) {
            if (response.status === 401) {
                logOut()
            }
            throw response
        }
    }

    return (await response.json()) as Promise<T>
}
