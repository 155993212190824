export async function responseToNotificationMessage(response: Response) {
    const statusMessage = `${response.status} ${response.statusText}`
    try {
        const data = await response.json()
        if ('detail' in data && typeof data.detail === 'string') {
            return data.detail
        } else {
            return statusMessage
        }
    } catch {
        return statusMessage
    }
}
