import { css } from '@emotion/react'
import { useTheme } from '@mui/material'

export const useStyles = () => {
    const theme = useTheme()

    return {
        groupWrapper: css`
            width: 100%;

            &:not(:first-of-type) {
                margin-top: ${theme.spacing(1)};
            }

            @media (min-width: 1023px) {
                width: 48%;

                &:not(:first-of-type) {
                    margin-top: ${theme.spacing(2)};
                }

                &:nth-of-type(4) {
                    margin-top: 0;
                }
            }
        `,
    }
}
