import React from 'react'
import { SvgIcon } from '@mui/material'

const XMark = ({ width = 24, height = 24 }: { width?: number; height?: number }) => {
    return (
        <SvgIcon style={{ width, height }}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
            >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </SvgIcon>
    )
}

export default XMark
