export const DEFAULT_OAT_MAX = +52
export const DEFAULT_OAT_MIN = -40

export const DEFAULT_PRESSURE_ALTITUDE_MAX = 10000
export const DEFAULT_PRESSURE_ALTITUDE_MIN = -1000

export const DEFAULT_HEADWIND_MAX = 15
export const DEFAULT_HEADWIND_MIN = -5

export const ISA_CELSIUS_SEA_LEVEL = 15.0
export const ISA_STEP_CELSIUS_PER_FEET = 0.002
export const CTCRJ_ISA_ERROR_FACTOR = 0.1
export const MAX_ISA_DEVIATION_CELSIUS = 35
