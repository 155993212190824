/** @jsxImportSource @emotion/react */
import React from 'react'

import { formResultCtx } from '../../../context/formResult/formResultContext'
import ResultInfoBlock from './ResultInfoBlock'
import { IResultData } from '../../../context/formResult/types'

export const DEFAULT_ELEMENTS_PER_PAGE_RESULTS = 6
const NORMAL_ELEMENTS_PER_PAGE_RESULTS = 5
const FLEX_ELEMENTS_PER_PAGE_RESULTS = 6

const NORMAL_TITLE_RESULTS = 'Normal take-off'
const FLEX_TITLE_RESULTS = 'Reduced Thrust take-off'

export interface IBlockData {
    title: string
    data: IResultData[]
    maxElementsPerPage: number | null
}

const Results = () => {
    const { normal, flex } = React.useContext(formResultCtx).result

    const data: IBlockData[] = React.useMemo(() => {
        return [
            { data: normal, title: NORMAL_TITLE_RESULTS, maxElementsPerPage: NORMAL_ELEMENTS_PER_PAGE_RESULTS },
            { data: flex, title: FLEX_TITLE_RESULTS, maxElementsPerPage: FLEX_ELEMENTS_PER_PAGE_RESULTS },
        ].filter(({ data }) => Boolean(data)) as IBlockData[]
    }, [normal, flex])

    return React.useMemo(
        () => (
            <>
                {data.map((el, index) => (
                    <ResultInfoBlock key={index} {...el} />
                ))}
            </>
        ),
        [data],
    )
}

export default React.memo(Results)
