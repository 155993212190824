/** @jsxImportSource @emotion/react */
import React from 'react'
import { Button } from '@mui/material'

import { formCtx } from '../../../context/form/formContext'
import { useStyles } from './useStyles'
import { formResultCtx } from '../../../context/formResult/formResultContext'

const ApplyButton = React.memo(({ isLoading }: { isLoading: boolean }) => {
    const formData = React.useContext(formCtx)
    const { normal, flex } = React.useContext(formResultCtx).result
    const {
        database,
        runway_id,
        surface_condition,
        oat,
        pressure_altitude,
        wind_component,
        takeoff_procedure,
        takeoff_weight,
        acu,
        apu,
        anti_icing,
        ground_spoilers,
        anti_skid,
        thrust_reverse,
        landing_gear,
        hydraulic_pumps,
        nw_steering,
        apr,
        ecprv,
    } = formData
    const classes = useStyles()

    const isDisabled = React.useMemo(() => {
        switch (true) {
            case isLoading:
            case [
                database.value,
                runway_id.value,
                surface_condition.value,
                oat.value,
                pressure_altitude.value,
                wind_component.value,
                takeoff_procedure.value,
                takeoff_weight.value,
                acu.value,
                apu.value,
                anti_icing.value,
                ground_spoilers.value,
                anti_skid.value,
                thrust_reverse.value,
                landing_gear.value,
                hydraulic_pumps.value,
                nw_steering.value,
                apr.value,
                ecprv.value,
            ].some((value) => value === null): // не заполнено значение обязательных полей
            case [oat.error, pressure_altitude.error, wind_component.error, takeoff_weight.error].some(Boolean): {
                // ошибка
                return true
            }
            default:
                return false
        }
    }, [
        database.value,
        runway_id.value,
        surface_condition.value,
        oat.value,
        pressure_altitude.value,
        wind_component.value,
        takeoff_procedure.value,
        takeoff_weight.value,
        acu.value,
        apu.value,
        anti_icing.value,
        ground_spoilers.value,
        anti_skid.value,
        thrust_reverse.value,
        landing_gear.value,
        hydraulic_pumps.value,
        nw_steering.value,
        apr.value,
        ecprv.value,
        oat.error,
        pressure_altitude.error,
        wind_component.error,
        takeoff_weight.error,
        isLoading,
    ])

    const isButtonHidden = normal !== null || flex !== null

    return React.useMemo(
        () => (
            <div css={[classes.applyButtonWrapper, isButtonHidden && classes.hidden]}>
                <Button variant="contained" css={classes.applyButton} type="submit" fullWidth disabled={isDisabled}>
                    Calculate
                </Button>
            </div>
        ),
        [isButtonHidden, isDisabled, classes],
    )
})

export default ApplyButton
