/** @jsxImportSource @emotion/react */
import React from 'react'

import { useStyles } from '../../useStyles'
import GroupTitle from '../GroupTitle/GroupTitle'
import RunwaySurfaceConditionSelect from './Inputs/RunwaySurfaceConditionSelect'
import MyNumInput from '../../../ui/components/MyNumInput/MyNumInput'
import GroupWrapper from '../GroupWrapper/GroupWrapper'
import OatInput from './OatInput'
import {
    DEFAULT_HEADWIND_MAX,
    DEFAULT_HEADWIND_MIN,
    DEFAULT_PRESSURE_ALTITUDE_MAX,
    DEFAULT_PRESSURE_ALTITUDE_MIN,
} from './constants'

const WeatherConditions = () => {
    const classes = useStyles()

    return (
        <GroupWrapper>
            <GroupTitle title={'Weather conditions'} />
            <RunwaySurfaceConditionSelect />
            <div css={classes.numberInputsWrapper}>
                <OatInput />
                <MyNumInput
                    min={DEFAULT_PRESSURE_ALTITUDE_MIN}
                    max={DEFAULT_PRESSURE_ALTITUDE_MAX}
                    name="pressure_altitude"
                    label="Pressure alt. (feet)"
                    required={true}
                    css={classes.numberInput}
                />
                <MyNumInput
                    min={DEFAULT_HEADWIND_MIN}
                    max={DEFAULT_HEADWIND_MAX}
                    name="wind_component"
                    label="Wind component (m/s)"
                    required={true}
                    css={classes.numberInputTailwind}
                />
            </div>
        </GroupWrapper>
    )
}

export default WeatherConditions
