import React from 'react'

import { notificationSetterCtx } from '../context/notifications/notificationsContext'
import { formCtx, formSetterCtx } from '../context/form/formContext'
import { NotificationTypes } from '../context/notifications/types'
import { FormCtx } from '../context/form/types'
import useIsLoading from './useIsLoading'
import { FormResultCtxType } from '../context/formResult/types'
import { formResultSetterCtx } from '../context/formResult/formResultContext'
import { modalSetterCtx } from '../context/modal/modalContext'
import useFetch from './useFetch'
import { responseToNotificationMessage } from './utils'

const MAX_TRIES = 50

const LIMIT_CODE_DESCRIPTIONS = new Map<number, string>([
    [1, 'maximum selected computational weight'],
    [2, 'all engine takeoff distance'],
    [3, 'one engine out takeoff distance'],
    [4, 'accelerate stop distance'],
    [9, 'minimum certificated V1 (or V1mcg)'],
    [10, 'tire speed'],
    [12, 'maximum brake energy'],
    [15, 'second segment climb gradient'],
    [16, 'takeoff thrust time limited'],
    [19, 'obstacle clearance'],
])
interface IValidationError {
    loc: ['string', keyof FormCtx | '__root__']
    msg: string
}

export default () => {
    const { isLoading, toggleIsLoading } = useIsLoading()
    const setNotification = React.useContext(notificationSetterCtx)
    const showAlertModal = React.useContext(modalSetterCtx).showAlertModal
    const showAlertTowLimitedModal = React.useContext(modalSetterCtx).showAlertTowLimitedModal
    const setFormData = React.useContext(formSetterCtx)
    const setResultData = React.useContext(formResultSetterCtx)
    const formData = React.useContext(formCtx)
    const fetcher = useFetch()

    const {
        database,
        runway_id,
        takeoff_weight,
        takeoff_procedure,
        oat,
        wind_component,
        pressure_altitude,
        surface_condition,
        acu,
        anti_icing,
        ground_spoilers,
        anti_skid,
        thrust_reverse,
        landing_gear,
        apu,
        hydraulic_pumps,
        nw_steering,
        apr,
        ecprv,
    } = formData

    const makeTowLimitedMessage = (res: FormResultCtxType) => {
        const { max_allowed_tow } = res.result
        const initial_string = `Maximum takeoff weight is ${max_allowed_tow} kg`
        if (res.result.limit_codes === null) {
            return initial_string
        }
        let explanations: string[] = []
        for (let limit_code of res.result.limit_codes) {
            const limit_description = LIMIT_CODE_DESCRIPTIONS.get(limit_code)
            if (limit_description && !explanations.includes(limit_description)) {
                explanations.push(limit_description)
            }
        }
        const explanation = explanations.join(' and ')
        let msg = `${initial_string} (limited by ${explanation})`
        return msg
    }

    const onSubmit = async (e: any) => {
        e.preventDefault()

        setFormData((prev) => {
            return {
                ...prev,
                database: { ...prev.database, error: false },
                runway_id: { ...prev.runway_id, error: false },
                surface_condition: { ...prev.surface_condition, error: false },
                acu: { ...prev.acu, error: false },
                anti_icing: { ...prev.anti_icing, error: false },
                takeoff_procedure: { ...prev.takeoff_procedure, error: false },
                apu: { ...prev.apu, error: false },
                anti_skid: { ...prev.anti_skid, error: false },
                takeoff_weight: { ...prev.takeoff_weight, error: false },
                oat: { ...prev.oat, error: false },
                wind_component: { ...prev.wind_component, error: false },
                pressure_altitude: { ...prev.pressure_altitude, error: false },
                ground_spoilers: { ...prev.ground_spoilers, error: false },
                thrust_reverse: { ...prev.thrust_reverse, error: false },
                landing_gear: { ...prev.landing_gear, error: false },
                hydraulic_pumps: { ...prev.hydraulic_pumps, error: false },
                nw_steering: { ...prev.nw_steering, error: false },
                apr: { ...prev.apr, error: false },
                ecprv: { ...prev.ecprv, error: false },
            }
        })

        setNotification(null)
        toggleIsLoading()
        try {
            const { task_id } = await fetcher<{ task_id: string }>(`perf/task`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    database: database.value,
                    runway_id: runway_id.value,
                    surface_condition: surface_condition.value,
                    acu: acu.value,
                    anti_icing: anti_icing.value,
                    takeoff_procedure: takeoff_procedure.value,
                    apu: apu.value,
                    anti_skid: anti_skid.value,
                    takeoff_weight: takeoff_weight.value,
                    oat: oat.value,
                    wind_component: wind_component.value,
                    pressure_altitude: pressure_altitude.value,
                    ground_spoilers: ground_spoilers.value,
                    thrust_reverse: thrust_reverse.value,
                    landing_gear: landing_gear.value,
                    hydraulic_pumps: hydraulic_pumps.value,
                    nw_steering: nw_steering.value,
                    apr: apr.value,
                    ecprv: ecprv.value,
                }),
            })

            let tries = MAX_TRIES
            const fetchResult = async (task_id: string) => {
                return fetcher<FormResultCtxType>(`perf/task/${task_id}`, {
                    method: 'GET',
                    credentials: 'include',
                }).then((data) => data)
            }

            const getResult = () => {
                setTimeout(async () => {
                    const res: FormResultCtxType = await fetchResult(task_id)

                    if (res.status === 'PENDING' && tries === 0) {
                        setNotification({
                            type: NotificationTypes.ERROR,
                            message: `Can't get results, try again.`,
                        })
                        return
                    }

                    if (res.status === 'PENDING' && tries > 0) {
                        tries--
                        getResult()
                    }

                    if (res.status === 'SUCCESS') {
                        switch (res.result.status) {
                            case 'OK': {
                                setResultData(res)
                                break
                            }
                            case 'TOW_LIMITED': {
                                const { max_allowed_tow } = res.result
                                showAlertTowLimitedModal({
                                    title: 'Takeoff weight limited',
                                    message: makeTowLimitedMessage(res),
                                    mtow: max_allowed_tow ?? 0,
                                })
                                break
                            }
                            case 'SCAP_ERROR': {
                                showAlertModal({
                                    title: 'SCAP calculation error',
                                    message: res.result.error_text ?? 'Error text not available',
                                })
                                break
                            }
                        }
                    }
                }, 250)
            }
            getResult()
        } catch (err: any) {
            if (err instanceof Response) {
                if (err.status === 422) {
                    const errorData: { detail: IValidationError[] } = await err.json()

                    let errMessage = ''
                    errorData.detail.forEach((data) => {
                        errMessage =
                            errMessage + `${data.msg} ${data.loc[1] === '__root__' ? '' : `[${data.loc[1]}]`} \n`
                        setFormData((prev) => {
                            if (data.loc[1] !== '__root__' && prev[data.loc[1]]) {
                                return {
                                    ...prev,
                                    [data.loc[1]]: {
                                        ...prev[data.loc[1]],
                                        error: true,
                                    },
                                }
                            }

                            return prev
                        })
                    })

                    setNotification({
                        type: NotificationTypes.ERROR,
                        message: errMessage,
                    })
                } else {
                    const message = await responseToNotificationMessage(err)
                    setNotification({
                        type: NotificationTypes.ERROR,
                        message: message,
                    })
                }
            } else {
                setNotification({
                    type: NotificationTypes.ERROR,
                    message: `Something went wrong: ${err.message || err}`,
                })
            }
        } finally {
            toggleIsLoading()
        }
    }

    return {
        isLoading,
        onSubmit,
    }
}
