/** @jsxImportSource @emotion/react */
import React from 'react'
import { FormControl, FormHelperText, InputLabel } from '@mui/material'

import { MyInput } from '../MyInput/MyInput'
import { formCtx, formSetterCtx } from '../../../../context/form/formContext'
import useStyles from './useStyles'

interface INumInputProps {
    min: number
    max: number
    required?: boolean
    label: React.ReactNode
    name: 'oat' | 'pressure_altitude' | 'wind_component' | 'takeoff_weight'
    className?: string
}

const MyNumInput = ({ min, max, label, name, className }: INumInputProps) => {
    const valueSetter = React.useContext(formSetterCtx)
    const { value, error, errorText } = React.useContext(formCtx)[name]
    const classes = useStyles(error, value === null)

    const onChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const isNoError = +e.target.value >= min && +e.target.value <= max

            valueSetter((prev) => ({
                ...prev,
                [name]: {
                    ...prev[name],
                    value: e.target.value === '' ? null : +e.target.value,
                    error: isNoError ? false : prev[name].error,
                    errorText: isNoError ? undefined : prev[name].errorText,
                },
            }))
        },
        [min, max, name, valueSetter],
    )

    const onBlur = React.useCallback(
        (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const value = +e.target.value

            if (e.target.value !== '') {
                if (value < min || value > max) {
                    valueSetter((prev) => ({
                        ...prev,
                        [name]: {
                            ...prev[name],
                            error: true,
                        },
                    }))
                }
            }
        },
        [min, max, name, valueSetter],
    )

    return React.useMemo(
        () => (
            <FormControl css={className || ''}>
                <InputLabel css={classes.label}>{label}</InputLabel>
                <MyInput
                    inputProps={{ max, min }}
                    type="number"
                    name={name}
                    value={value === null ? '' : value}
                    onChange={onChange}
                    css={classes.input}
                    onBlur={onBlur}
                />
                <FormHelperText css={classes.helperText}>{errorText || `Min: ${min} Max: ${max}`}</FormHelperText>
            </FormControl>
        ),
        [min, max, onChange, onBlur, value, className, classes, label, name, errorText],
    )
}

export default React.memo(MyNumInput)
