import React from 'react'

import { NotificationCtxType, NotificationSetterCtxType } from './types'

export const notificationCtx = React.createContext<NotificationCtxType>(null)
export const notificationSetterCtx = React.createContext<NotificationSetterCtxType>(() => null)

const NotificationContext = ({ children }: { children?: React.ReactNode }) => {
    const [notification, setNotification] = React.useState<NotificationCtxType>(null)

    return (
        <notificationCtx.Provider value={notification}>
            <notificationSetterCtx.Provider value={setNotification}>{children}</notificationSetterCtx.Provider>
        </notificationCtx.Provider>
    )
}

export default NotificationContext
