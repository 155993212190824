/** @jsxImportSource @emotion/react */
import React from 'react'
import { Typography } from '@mui/material'

import useStyles from './useStyles'

const ResultBlockInfoRow = React.memo(({ title, value }: { title: string | null; value: number | string | null }) => {
    const classes = useStyles()

    return (
        <div css={[classes.resultBlockInfoRow, title === null && classes.resultBlockInfoRowEmpty]}>
            <Typography css={classes.resultBlockInfoRowTitle}>{title}</Typography>
            <div css={classes.dotedLine} />
            <Typography css={classes.resultBlockInfoRowTitle}>{value === null ? 'N/A' : value}</Typography>
        </div>
    )
})

export default ResultBlockInfoRow
