/** @jsxImportSource @emotion/react */
import React from 'react'

import MainFormHeader from './MainFormHeader/MainFormHeader'
import { useStyles } from './useStyles'
import PerformanceDatabase from './Groups/PerfomanceDatabase/PerformanceDatabase'
import RunwayData from './Groups/RunwayData/RunwayData'
import WeatherConditions from './Groups/WeatherConditions/WeatherConditions'
import TakeoffParameters from './Groups/TakeoffParameters/TakeoffParameters'
import AircraftConfiguration from './Groups/AircraftConfiguration/AircraftConfiguration'
import AircraftMalfunctions from './Groups/AircraftMalfunctions/AircraftMalfunctions'
import ApplyButton from './ApplyButton/ApplyButton'
import useSubmit from '../../hooks/useSubmit'
import Loader from '../ui/components/Loader'
import Results from './Results/Results'
import { formResultCtx } from '../../context/formResult/formResultContext'
import FlightVertical from '../ui/Icons/FlightVertical'
import FlightHorizontal from '../ui/Icons/FlightHorizontal'
import Modal from '../Modals/Modal'
const MainForm = () => {
    const { flex } = React.useContext(formResultCtx).result
    const classes = useStyles(!!flex)
    const { onSubmit, isLoading } = useSubmit()

    return (
        <>
            <Loader isShow={isLoading} />
            <MainFormHeader />
            <form css={classes.form} onSubmit={onSubmit}>
                <div css={classes.leftPart}>
                    <div css={classes.groupsWrapper}>
                        <PerformanceDatabase />
                        <RunwayData />
                        <WeatherConditions />
                        <TakeoffParameters />
                        <AircraftConfiguration />
                        <AircraftMalfunctions />
                    </div>
                </div>
                <div css={classes.resultsWrapper}>
                    <Results />
                    <ApplyButton isLoading={isLoading} />
                </div>
                <Modal />
            </form>
            <FlightVertical css={classes.flightIconVertical} />
            <FlightHorizontal css={classes.flightIconHorizontal} />
        </>
    )
}

export default MainForm
