import React from 'react'
import { css } from '@emotion/react'
import { useTheme } from '@mui/material'

export const useStyles = (verticalBlockDisplayed?: boolean) => {
    const theme = useTheme()

    return React.useMemo(
        () => ({
            form: css`
                padding: ${theme.spacing(2)};
                text-align: left;
                display: flex;
                justify-content: space-between;
                height: calc(100vh - 41px);
                position: relative;

                @media (max-height: 1024px) and (max-width: 768px) {
                    padding: ${theme.spacing(1)};
                }
            `,
            label: css`
          top: -5px;
          color: ${theme.palette.error.light};
          font-size: 16px;
          line-height: 19px;
            &.Mui-focused {
              display: none;
            },
            &.MuiFormLabel-filled {
              display: none;
            },
        `,
            numberInputsWrapper: css`
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            `,
            numberInput: css`
                width: 49%;
                margin-top: ${theme.spacing(2)};

                & .MuiFormLabel-root {
                    top: -2px;
                }
            `,
            numberInputTailwind: css`
                width: 49%;
                margin-top: ${theme.spacing(2)};

                & .MuiFormLabel-root {
                    top: -2px;
                    font-size: 14px;
                }
            `,
            flightIconVertical: css`
                right: 0;
                bottom: ${verticalBlockDisplayed ? '-130px' : 0};
                position: fixed;
                background-color: transparent;
                z-index: -10;

                @media (min-width: 1023px) {
                    display: none;
                }
            `,
            flightIconHorizontal: css`
                right: 0;
                bottom: 20px;
                position: fixed;
                background-color: transparent;
                z-index: -10;

                @media (max-width: 1023px) {
                    display: none;
                }
            `,
            leftPart: css`
                width: 48%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                @media (min-width: 1023px) {
                    width: 70%;
                }
            `,
            groupsWrapper: css`
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                width: 100%;

                @media (min-width: 1023px) {
                    max-height: 650px;
                }
            `,
            resultsWrapper: css`
                width: 48%;

                @media (min-width: 1023px) {
                    width: 30%;
                }
            `,
        }),
        [verticalBlockDisplayed, theme],
    )
}
