/** @jsxImportSource @emotion/react */
import React from 'react'
import { Button, FormControl, IconButton, Input, InputAdornment, InputLabel, Typography } from '@mui/material'

import useLogin from '../../hooks/useLogin'
import { useStyles } from './useStyles'
import Eye from '../ui/Icons/Eye'
import EyeSlash from '../ui/Icons/EyeSlash'

const Login = () => {
    const { handleLogin, isLoading, isError, setIsShowPasswordToggle, isShowPassword } = useLogin()
    const styles = useStyles()

    return (
        <div css={styles.wrapper}>
            <form onSubmit={handleLogin} css={styles.loginForm}>
                <Typography align="center" variant="subtitle2" css={styles.title}>
                    SCAP
                </Typography>
                <FormControl css={styles.input}>
                    <InputLabel htmlFor="my-input">Email</InputLabel>
                    <Input
                        id="email_SCAP"
                        name="email_SCAP"
                        type="email"
                        aria-describedby="login"
                        autoComplete="on"
                        error={isError}
                        required
                    />
                </FormControl>
                <FormControl css={styles.input}>
                    <InputLabel htmlFor="my-input">Password</InputLabel>
                    <Input
                        id="password_SCAP"
                        name="password_SCAP"
                        aria-describedby="password_SCAP"
                        type={isShowPassword ? 'text' : 'password'}
                        autoComplete="on"
                        error={isError}
                        required
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    edge="end"
                                    onClick={setIsShowPasswordToggle}
                                >
                                    {isShowPassword ? <EyeSlash /> : <Eye />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <Button type="submit" variant="contained" disabled={isLoading}>
                    Log in
                </Button>
            </form>
        </div>
    )
}

export default Login
