import React from 'react'

import { useTheme } from '@mui/material'
import { css } from '@emotion/react'

export default () => {
    const theme = useTheme()

    return React.useMemo(
        () => ({
            resultBlockWrapper: css`
                background-color: ${theme.palette.primary.main};
                border-radius: 8px;
                padding: ${theme.spacing(1.5, 2.5, 1, 3)};
                color: ${theme.palette.secondary.main};

                &:not(:first-of-type) {
                    margin-top: ${theme.spacing(2)};
                }

                & .carousel {
                    display: flex;
                    flex-direction: column-reverse;
                }

                & .control-dots {
                    position: static;
                    margin: 0;

                    & .dot {
                        box-shadow: none;
                        background-color: ${theme.palette.secondary.main};
                    }
                }
            `,
            resultBlockTitle: css`
                text-transform: uppercase;
                font-family: 'Roboto Condensed';
                font-weight: 700;
                font-size: 20px;
                text-align: center;
                margin-bottom: ${theme.spacing(1)};
            `,
            resultBlockInfoRow: css`
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: baseline;

                &:not(:first-of-type) {
                    margin-top: ${theme.spacing(1)};
                }
            `,
            resultBlockInfoRowEmpty: css`
                opacity: 0;
            `,
            resultBlockInfoRowTitle: css`
                font-family: 'Roboto Condensed';
                font-weight: 700;
                font-size: 20px;
            `,
            dotedLine: css`
                height: 1px;
                flex-grow: 1;
                margin-left: ${theme.spacing(1)};
                margin-right: ${theme.spacing(1)};
                background-image: linear-gradient(
                    to right,
                    ${theme.palette.secondary.main} 25%,
                    rgba(255, 255, 255, 0) 0%
                );
                background-position: top;
                background-size: 8px 1px;
                background-repeat: repeat-x;
            `,
            paginationStack: css`
                align-items: center;
                margin-top: ${theme.spacing(0.5)};

                & .MuiButtonBase-root {
                    color: ${theme.palette.secondary.main};

                    &.Mui-selected {
                        color: ${theme.palette.info.main};
                    }
                }
            `,
            resultRowsWrapper: css`
                height: 220px;
            `,
            carouselPage: css`
                margin: ${theme.spacing(0, 0.5)};
            `,
        }),
        [theme],
    )
}
