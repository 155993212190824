import React from 'react'
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material'

import { Notification } from './Notification/Notification'
import Login from './Login/Login'
import MainForm from './MainForm/MainForm'
import { userCtx } from '../context/user/userContext'
import useAuth from '../hooks/useAuth'
import LoadPage from './LoadPage/LoadPage'
import { themeCtx } from '../context/theme/theme'

const MyApp = () => {
    const user = React.useContext(userCtx)
    const themeMode = React.useContext(themeCtx)
    const { isAuthProcessing } = useAuth()

    const theme = React.useMemo(() => {
        const isLight = themeMode === 'light'
        return createTheme({
            palette: {
                mode: themeMode,
                primary: {
                    main: '#5992F1',
                },
                secondary: {
                    main: isLight ? '#fff' : '#323232',
                },
                info: {
                    main: '#8991A4',
                },
                background: {
                    default: isLight ? '#fff' : '#323232',
                },
            },
            components: {
                MuiCssBaseline: {
                    styleOverrides: (theme) => `
                .MuiButton-root.MuiButton-contained {
                  color: ${theme.palette.secondary.main};
                },
                .MuiButtonBase-root.MuiIconButton-root {
                  color: ${theme.palette.secondary.main};
                }
            `,
                },
            },
        })
    }, [themeMode])

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <>
                {isAuthProcessing && <LoadPage />}
                {user?.id && !isAuthProcessing ? <MainForm /> : <Login />}
                <Notification />
            </>
        </ThemeProvider>
    )
}

export default MyApp
