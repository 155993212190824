import React from 'react'
import { ModalCtxType, ModalSetterCtxType, ModalTypes } from './types'

const initialModalCtx = { type: null }

export const modalCtx = React.createContext<ModalCtxType>(initialModalCtx)
export const modalSetterCtx = React.createContext<ModalSetterCtxType>({} as ModalSetterCtxType)

const ModalContext = ({ children }: { children?: React.ReactNode }) => {
    const [modal, setModal] = React.useState<ModalCtxType>(initialModalCtx)

    const modalCallbacks = React.useMemo(() => {
        return {
            showMalfunctionModal: () => setModal({ type: ModalTypes.MALFUNCTIONS_MODAL }),
            showAlertModal: (data: { title: string; message: string }) =>
                setModal({ type: ModalTypes.ALERT_MODAL, payload: data }),
            showAlertTowLimitedModal: (data: { title: string; message: string; mtow: number }) =>
                setModal({ type: ModalTypes.ALERT_TOW_LIMITED_MODAL, payload: data }),
            hideModal: () => setModal(initialModalCtx),
        }
    }, [setModal])

    return (
        <modalCtx.Provider value={modal}>
            <modalSetterCtx.Provider value={modalCallbacks}>{children}</modalSetterCtx.Provider>
        </modalCtx.Provider>
    )
}

export default ModalContext
