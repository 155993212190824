import React from 'react'

import { RunwayInfoCtxType, RunwayInfoSetterCtxType } from './types'

export const initialRunwayInfoCtx = {
    tora: null,
    toda: null,
    asda: null,
}

export const runwayInfoCtx = React.createContext<RunwayInfoCtxType>(initialRunwayInfoCtx)
export const runwayInfoSetterCtx = React.createContext<RunwayInfoSetterCtxType>(() => null)

const NotificationContext = ({ children }: { children?: React.ReactNode }) => {
    const [runwayInfo, setRunwayInfo] = React.useState<RunwayInfoCtxType>(initialRunwayInfoCtx)

    return (
        <runwayInfoCtx.Provider value={runwayInfo}>
            <runwayInfoSetterCtx.Provider value={setRunwayInfo}>{children}</runwayInfoSetterCtx.Provider>
        </runwayInfoCtx.Provider>
    )
}

export default NotificationContext
