import { css } from '@emotion/react'
import { useTheme } from '@mui/material'

export const useStyles = () => {
    const theme = useTheme()

    return {
        select: css`
            font-size: 16px;
            line-height: 19px;
            color: ${theme.palette.info.main};
        `,
        menuItem: css`
            color: ${theme.palette.info.main};
        `,
    }
}
