import React, { SetStateAction } from 'react'

export enum NotificationTypes {
    SUCCESS,
    ERROR,
    INFO,
}

export interface Notification {
    type: NotificationTypes
    message: string
}

export type NotificationCtxType = null | Notification
export type NotificationSetterCtxType = React.Dispatch<SetStateAction<NotificationCtxType>>
