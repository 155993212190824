/** @jsxImportSource @emotion/react */
import React from 'react'
import { Typography } from '@mui/material'

import { useStyles } from './useStyles'

const GroupTitle = ({ title }: { title: string }) => {
    const classes = useStyles()

    return (
        <div css={classes.wrapper}>
            <Typography css={classes.title}>{title}</Typography>
            <div css={classes.line}></div>
        </div>
    )
}

export default React.memo(GroupTitle)
