/** @jsxImportSource @emotion/react */
import * as React from 'react'
import { FormControl, InputLabel } from '@mui/material'

import MySelect from '../../ui/components/MySelect/MySelect'
import { IOptionMalfunctions } from './types'
import { formCtx, formSetterCtx, initialFormCtxData, initialMalfunctions } from '../../../context/form/formContext'
import { MalfunctionsSelectsNames } from './AircraftMalfunctionsModal'
import { useStyles } from './useStyles'
import { malfunctionsSetterCtx } from '../../../context/malfunctions/malfunctions'

interface IAircraftMalfunctionsModalSelect {
    options: IOptionMalfunctions[]
    name: MalfunctionsSelectsNames
    label: string
}

const AircraftMalfunctionsModalSelect = ({ options, name, label }: IAircraftMalfunctionsModalSelect) => {
    const { value, error } = React.useContext(formCtx)[name]
    const setFormData = React.useContext(formSetterCtx)
    const setMalfunctions = React.useContext(malfunctionsSetterCtx)
    const classes = useStyles()
    const defaultValue = React.useMemo(() => {
        return initialMalfunctions[name].value
    }, [name])

    const isDefaultValue = value === defaultValue
    const setValue = React.useCallback(
        (value: number) => {
            const option = options.find((option) => option.value === value) as IOptionMalfunctions
            setFormData((prev) => ({
                ...prev,
                [name]: { ...prev[name], value: option }.value,
            }))

            setMalfunctions((prev) => {
                const res = prev.filter((el) => el.name !== name)
                if (option.value !== initialFormCtxData[name].value) {
                    res.push({ ...option, name, displayText: option.displayText || '' })
                }
                return res
            })
        },
        [name, options, setFormData, setMalfunctions],
    )

    return React.useMemo(
        () => (
            <FormControl
                css={isDefaultValue ? classes.inputWrapper : [classes.inputWrapper, classes.inputWrapperNotDefault]}
            >
                <InputLabel error={error}>{label}</InputLabel>
                <MySelect options={options} value={value} setValue={setValue} name={name} error={error} />
            </FormControl>
        ),
        [value, error, setValue, options, name, classes, label, isDefaultValue],
    )
}

export default AircraftMalfunctionsModalSelect
