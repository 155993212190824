import { css } from '@emotion/react'
import { useTheme } from '@mui/material'

export const useStyles = () => {
    const theme = useTheme()

    return {
        title: css`
            font-size: 20px;
        `,
        wrapper: css`
            width: 100vw;
            height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
        `,
        loginForm: css`
            border: 1px solid ${theme.palette.primary.main};
            border-radius: ${theme.spacing(0.5)};
            padding: ${theme.spacing(2)};
        `,
        input: css`
            display: flex;
            margin: ${theme.spacing(3, 0)};
            min-width: 250px;
        `,
    }
}
