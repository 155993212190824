/** @jsxImportSource @emotion/react */
import React from 'react'
import { IconButton } from '@mui/material'

import GroupTitle from '../GroupTitle/GroupTitle'
import { useStyles } from './useStyles'
import Cog from '../../../ui/Icons/Cog'
import { modalSetterCtx } from '../../../../context/modal/modalContext'
import GroupWrapper from '../GroupWrapper/GroupWrapper'
import { AircraftMalfunctionInfo } from './AircraftMalfunctionsInfo'

const AircraftMalfunctions = () => {
    const classes = useStyles()
    const showMalfunctionModal = React.useContext(modalSetterCtx).showMalfunctionModal

    return React.useMemo(
        () => (
            <GroupWrapper>
                <GroupTitle title={'Aircraft malfunctions'} />
                <div css={classes.malfunctionsWrapper}>
                    <AircraftMalfunctionInfo />
                    <IconButton css={classes.malfunctionsButton} onClick={showMalfunctionModal}>
                        <Cog />
                    </IconButton>
                </div>
            </GroupWrapper>
        ),
        [showMalfunctionModal, classes],
    )
}

export default AircraftMalfunctions
