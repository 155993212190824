export enum ModalTypes {
    MALFUNCTIONS_MODAL,
    ALERT_MODAL,
    ALERT_TOW_LIMITED_MODAL,
}

export interface AlertModalPayload {
    title: string
    message: string
}

export interface AlertTowLimitedModalPayload extends AlertModalPayload {
    mtow: number
}

export type ModalCtxType = { type: ModalTypes | null; payload?: AlertModalPayload | AlertTowLimitedModalPayload }
export type ModalSetterCtxType = {
    showMalfunctionModal: () => void
    showAlertModal: ({ title, message }: AlertModalPayload) => void
    showAlertTowLimitedModal: ({ title, message, mtow }: AlertTowLimitedModalPayload) => void
    hideModal: () => void
}
