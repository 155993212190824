/** @jsxImportSource @emotion/react */
import React from 'react'

import GroupTitle from '../GroupTitle/GroupTitle'
import MyRadioGroup from '../../../ui/components/MyRadioGroup/MyRadioGroup'
import useStyles from './useStyles'
import MyNumInput from '../../../ui/components/MyNumInput/MyNumInput'
import GroupWrapper from '../GroupWrapper/GroupWrapper'

const NAME_TAKEOFF_PROCEDURE = 'takeoff_procedure'
const NAME_TAKEOFF_WIGHT = 'takeoff_weight'

const options = [
    { value: 0, label: 'Normal' },
    { value: 1, label: 'Rolling' },
]

const TakeoffParameters = () => {
    const classes = useStyles()

    return (
        <GroupWrapper>
            <GroupTitle title={'Takeoff parameters'} />
            <MyRadioGroup
                name={NAME_TAKEOFF_PROCEDURE}
                options={options}
                css={classes.radioGroup}
                label={'Takeoff procedure'}
            />
            <MyNumInput
                min={13608}
                max={24040}
                name={NAME_TAKEOFF_WIGHT}
                label={'Takeoff weight'}
                required={true}
                css={classes.takeoffWeightInput}
            />
        </GroupWrapper>
    )
}

export default TakeoffParameters
