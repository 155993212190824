import React from 'react'
import { Typography, List, ListItem, Container } from '@mui/material'

const InstallPwaPrompt: React.FC = () => {
    return (
        <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '50px' }}>
            <Typography variant="h4" gutterBottom>
                Установите приложение
            </Typography>
            <Typography variant="body1" paragraph>
                Это приложение предназначено для использования на iPadOS в качестве PWA (Progressive Web Application).
            </Typography>
            <Typography variant="h6" gutterBottom>
                Шаги по установке:
            </Typography>
            <List>
                <ListItem>1. Откройте эту страницу в браузере Safari.</ListItem>
                <ListItem>
                    2. Нажмите на кнопку "Поделиться" рядом с адресной строкой (квадрат со стрелкой вверх).
                </ListItem>
                <ListItem>3. В появившемся меню выберите На экран "Домой".</ListItem>
                <ListItem>4. Нажмите "Добавить".</ListItem>
                <ListItem>Ярлык приложения появится на Вашем экране "Домой".</ListItem>
            </List>
        </Container>
    )
}

export default InstallPwaPrompt
