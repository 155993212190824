/** @jsxImportSource @emotion/react */
import React from 'react'
import { Button, IconButton, Typography } from '@mui/material'

import Reset from '../../ui/Icons/Reset'
import { useStyles } from './useStyles'
import useLogout from '../../../hooks/useLogout'
import Sun from '../../ui/Icons/Sun'
import { themeCtx, themeToggleCtx } from '../../../context/theme/theme'
import Moon from '../../ui/Icons/Moon'
import { formSetterCtx, initialFormCtxData } from '../../../context/form/formContext'
import { malfunctionsSetterCtx } from '../../../context/malfunctions/malfunctions'
import { runwayOptionsSetterCtx } from '../../../context/runwayOptions/runwayOptions'
import { initialRunwayInfoCtx, runwayInfoSetterCtx } from '../../../context/runwayInfo/runwayInfoContext'

const MainFormHeader = () => {
    const classes = useStyles()
    const { logOut } = useLogout()
    const themeMode = React.useContext(themeCtx)
    const themeToggle = React.useContext(themeToggleCtx)
    const formSetter = React.useContext(formSetterCtx)
    const runwayOptionsSetter = React.useContext(runwayOptionsSetterCtx)
    const malfunctionsSetter = React.useContext(malfunctionsSetterCtx)
    const runwayInfoSetter = React.useContext(runwayInfoSetterCtx)

    const resetForm = () => {
        formSetter(initialFormCtxData)
        runwayOptionsSetter([])
        malfunctionsSetter([])
        runwayInfoSetter(initialRunwayInfoCtx)
    }

    return (
        <div css={classes.wrapper}>
            <Button css={classes.headerButton} endIcon={<Reset />} onClick={resetForm}>
                Reset form
            </Button>
            <Typography css={classes.title}>SCAP-TO</Typography>
            <div>
                <IconButton css={classes.themeToggleButton} onClick={themeToggle}>
                    {themeMode === 'light' ? <Sun /> : <Moon />}
                </IconButton>
                <Button css={classes.headerButton} onClick={logOut}>
                    LOG OUT
                </Button>
            </div>
        </div>
    )
}

export default MainFormHeader
