import React from 'react'

import { css } from '@emotion/react'
import { useTheme } from '@mui/material'

export const useStyles = () => {
    const theme = useTheme()

    return React.useMemo(
        () => ({
            dialogWrapper: css`
                & .MuiPaper-root {
                    padding: ${theme.spacing(2, 2, 2, 2)};
                    width: 480px;
                    box-sizing: border-box;
                    position: relative;
                }
            `,
            dialogTitle: css`
                padding: 0;
                text-align: center;
                margin-bottom: ${theme.spacing(1)};
                color: ${theme.palette.info.main};
            `,
            inputsWrapper: css`
                display: flex;
                flex-wrap: wrap;
                width: 100%;
            `,
            inputWrapper: css`
                width: 220px;
                margin-top: ${theme.spacing(1.5)};
                &:nth-of-type(odd) {
                    margin-right: ${theme.spacing(1)};
                }
            `,
            inputWrapperNotDefault: css`
                & .MuiInputBase-root {
                    color: ${theme.palette.mode === 'light' ? 'black' : '#d2d5de'};
                    font-weight: 500;
                }
            `,
            dialogActions: css`
                display: flex;
                justify-content: center;
            `,
            actionButton: css`
                width: 166px;
                height: 46px;
            `,
            closeIcon: css`
                position: absolute;
                top: 5px;
                right: 5px;
                color: ${theme.palette.info.main};
            `,
        }),
        [theme],
    )
}
