import React from 'react'

import { css } from '@emotion/react'
import { useTheme } from '@mui/material'

export const useStyles = () => {
    const theme = useTheme()

    return React.useMemo(
        () => ({
            groupLabel: css`
                color: ${theme.palette.info.main};
            `,
            elementLabel: css`
                color: ${theme.palette.info.main};
                & .MuiRadio-root {
                    color: ${theme.palette.primary.main};
                }
            `,
        }),
        [theme],
    )
}
