import { css } from '@emotion/react'
import { useTheme } from '@mui/material'

export const useStyles = () => {
    const theme = useTheme()

    return {
        wrapper: css`
            display: flex;
            align-items: center;
        `,
        title: css`
            font-weight: 700;
            text-transform: uppercase;
            color: ${theme.palette.info.main};
        `,
        line: css`
          border-top: 1px solid ${theme.palette.info.main};
          flex-grow: 1;
          margin-left: ${theme.spacing(1)};
          box-sizing: border-box;
        }
        `,
    }
}
