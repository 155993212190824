import React from 'react'
import { css } from '@emotion/react'
import { useTheme } from '@mui/material'

export const useStyles = () => {
    const theme = useTheme()

    return React.useMemo(
        () => ({
            malfunctionsWrapper: css`
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
            `,
            malfunctionsInfoWrapper: css`
                padding: ${theme.spacing(1.5)};
                box-shadow: 0 2px 2px 0 #0000001a;
                flex-grow: 1;
                margin-right: ${theme.spacing(1.5)};
                border-radius: 8px;
            `,
            malfunctionsButton: css`
                background: ${theme.palette.primary.main};
                border-radius: 8px;
                color: white;
                width: 46px;
                height: 46px;
            `,
        }),
        [theme],
    )
}
