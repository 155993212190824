/** @jsxImportSource @emotion/react */
import React from 'react'

import MyNumInput from '../../../ui/components/MyNumInput/MyNumInput'
import { useStyles } from '../../useStyles'
import { formCtx, formSetterCtx } from '../../../../context/form/formContext'
import {
    CTCRJ_ISA_ERROR_FACTOR,
    DEFAULT_OAT_MAX,
    DEFAULT_OAT_MIN,
    DEFAULT_PRESSURE_ALTITUDE_MAX,
    DEFAULT_PRESSURE_ALTITUDE_MIN,
    ISA_CELSIUS_SEA_LEVEL,
    ISA_STEP_CELSIUS_PER_FEET,
    MAX_ISA_DEVIATION_CELSIUS,
} from './constants'
import useThrottle from '../../../../hooks/useThrottle'

const getIsaTemp = (pressure_altitude: number) => {
    return ISA_CELSIUS_SEA_LEVEL - pressure_altitude * ISA_STEP_CELSIUS_PER_FEET
}

const getMaxOat = (pressure_altitude: number) => {
    return Math.floor(getIsaTemp(pressure_altitude) + MAX_ISA_DEVIATION_CELSIUS - CTCRJ_ISA_ERROR_FACTOR)
}

const OatInput = () => {
    const classes = useStyles()
    const { pressure_altitude, oat } = React.useContext(formCtx)
    const [maxOat, setMaxOat] = React.useState(DEFAULT_OAT_MAX)
    const setForm = React.useContext(formSetterCtx)
    const throttle = useThrottle(1000)

    React.useEffect(() => {
        throttle(() => {
            const isPressureAltitudeValid =
                pressure_altitude.value !== null &&
                pressure_altitude.value >= DEFAULT_PRESSURE_ALTITUDE_MIN &&
                pressure_altitude.value <= DEFAULT_PRESSURE_ALTITUDE_MAX
            const maxOat = isPressureAltitudeValid ? getMaxOat(pressure_altitude.value as number) : DEFAULT_OAT_MAX
            setMaxOat(maxOat)
            if (oat.value !== null) {
                if (oat.value >= DEFAULT_OAT_MIN && oat.value <= maxOat) {
                    setForm((prev) => {
                        return {
                            ...prev,
                            oat: {
                                ...prev.oat,
                                error: false,
                            },
                        }
                    })
                } else if (oat.value > maxOat || oat.value < DEFAULT_OAT_MIN) {
                    setForm((prev) => {
                        return {
                            ...prev,
                            oat: {
                                ...prev.oat,
                                error: true,
                            },
                        }
                    })
                }
            }
        })
    }, [oat.value, pressure_altitude.value, setForm, setMaxOat, throttle])

    return (
        <MyNumInput
            min={DEFAULT_OAT_MIN}
            max={maxOat}
            name="oat"
            label="OAT (celsius)"
            required={true}
            css={classes.numberInput}
        />
    )
}

export default OatInput
