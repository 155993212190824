/** @jsxImportSource @emotion/react */
import * as React from 'react'
import Dialog from '@mui/material/Dialog'

import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { modalSetterCtx } from '../../../context/modal/modalContext'
import { formSetterCtx } from '../../../context/form/formContext'
import { useStyles } from './useStyles'

const AlertTowLimitedModal = ({ title, message, mtow }: { title: string; message: string; mtow: number }) => {
    const hideModal = React.useContext(modalSetterCtx).hideModal
    const setFormData = React.useContext(formSetterCtx)
    const classes = useStyles()

    const setTow = React.useCallback(() => {
        setFormData((prev) => {
            return {
                ...prev,
                takeoff_weight: {
                    ...prev.takeoff_weight,
                    value: mtow,
                },
            }
        })
        hideModal()
    }, [mtow, setFormData, hideModal])

    return React.useMemo(
        () => (
            <Dialog open={true}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" css={classes.message}>
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" variant="contained" onClick={setTow}>
                        Set TOW to {mtow} kg
                    </Button>
                    <Button color="primary" variant="contained" onClick={hideModal}>
                        Continue without changes
                    </Button>
                </DialogActions>
            </Dialog>
        ),
        [title, message, mtow, setTow, hideModal, classes],
    )
}

export default React.memo(AlertTowLimitedModal)
