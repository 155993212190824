/** @jsxImportSource @emotion/react */
import React from 'react'
import { FormControl, InputLabel } from '@mui/material'

import { useStyles } from '../../useStyles'
import GroupTitle from '../GroupTitle/GroupTitle'
import MySelect from '../../../ui/components/MySelect/MySelect'

import { formCtx, formSetterCtx } from '../../../../context/form/formContext'
import { userCtx } from '../../../../context/user/userContext'
import GroupWrapper from '../GroupWrapper/GroupWrapper'

const options = [
    { label: 'CRJ100 (3A1) Flaps 8', value: 'ctcrj3a1.dat' },
    { label: 'CRJ100 (3A1) Flaps 20', value: 'ctcrj3a2.dat' },
    { label: 'CRJ100 RR (3A1) Flaps 8', value: 'ctcrj3a5.dat' },
    { label: 'CRJ100 RR (3A1) Flaps 20', value: 'ctcrj3a6.dat' },
    { label: 'CRJ100 RR (3A1) A41, Flaps 8', value: 'ctcrj3a7.dat' },
    { label: 'CRJ100 RR (3A1) A41, Flaps 20', value: 'ctcrj3a8.dat' },
    { label: 'CRJ200 (3B1) Flaps 8', value: 'ctcrj3b1.dat' },
    { label: 'CRJ200 (3B1) Flaps 20', value: 'ctcrj3b2.dat' },
    { label: 'CRJ200 (3B1 - HH) Flaps 8', value: 'ctcrj3b3.dat' },
    { label: 'CRJ200 (3B1 - HH) Flaps 20', value: 'ctcrj3b4.dat' },
    { label: 'CRJ200 (3B1) A41, Flaps 8', value: 'ctcrj3b5.dat' },
    { label: 'CRJ200 (3B1) A41, Flaps 20', value: 'ctcrj3b6.dat' },
    { label: 'CRJ200 (3B1 - HH) A41, Flaps 8', value: 'ctcrj3b7.dat' },
    { label: 'CRJ200 (3B1 - HH) A41, Flaps 20', value: 'ctcrj3b8.dat' },
    { label: 'CRJ200 (3B1 - HH / HIALT) Flaps 8', value: 'ctcrj3b9.dat' },
]

const PerformanceDatabase = React.memo(() => {
    const classes = useStyles()
    const user = React.useContext(userCtx)
    const { database } = React.useContext(formCtx)
    const setter = React.useContext(formSetterCtx)

    const onSelectChange = React.useCallback(
        (data: string) => {
            setter((prev) => ({
                ...prev,
                database: { ...prev.database, value: data },
            }))
        },
        [setter],
    )

    const filtered_options = options.filter((obj) => user?.allowedDat.includes(obj.value))

    return React.useMemo(
        () => (
            <GroupWrapper>
                <GroupTitle title={'Performance database'} />
                <FormControl fullWidth>
                    <InputLabel css={classes.label}>Performance database</InputLabel>
                    <MySelect
                        options={filtered_options}
                        value={database.value || ''}
                        error={database.error}
                        setValue={onSelectChange}
                        name="database"
                    />
                </FormControl>
            </GroupWrapper>
        ),
        [database.value, database.error, classes, onSelectChange, filtered_options],
    )
})

export default PerformanceDatabase
