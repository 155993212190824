import { useContext } from 'react'

import { userSetterCtx } from '../context/user/userContext'
import { NotificationTypes } from '../context/notifications/types'
import { notificationSetterCtx } from '../context/notifications/notificationsContext'
import useFetch from './useFetch'
import { formSetterCtx, initialFormCtxData } from '../context/form/formContext'

export default () => {
    const userSetter = useContext(userSetterCtx)
    const notificationSetter = useContext(notificationSetterCtx)
    const formSetter = useContext(formSetterCtx)
    const fetcher = useFetch()

    const logOut = async () => {
        try {
            await fetcher(
                `/auth`,
                {
                    method: 'DELETE',
                    credentials: 'include',
                },
                false,
            )
            userSetter(null)
            formSetter(initialFormCtxData)
        } catch (error: any) {
            notificationSetter({
                type: NotificationTypes.ERROR,
                message: `Unknown server error occured: ${error.statusText}`,
            })
        }
    }

    return {
        logOut,
    }
}
