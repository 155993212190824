import React from 'react'

import { userSetterCtx } from '../context/user/userContext'
import { User } from '../context/user/types'
import { notificationSetterCtx } from '../context/notifications/notificationsContext'
import { NotificationTypes } from '../context/notifications/types'
import useIsLoading from './useIsLoading'
import useFetch from './useFetch'
import { responseToNotificationMessage } from './utils'

export default () => {
    const [isShowPassword, setIsShowPassword] = React.useState(false)
    const userSetter = React.useContext(userSetterCtx)
    const notificationSetter = React.useContext(notificationSetterCtx)
    const { isLoading, toggleIsLoading } = useIsLoading()
    const [isError, setIsError] = React.useState(false)
    const fetcher = useFetch()

    const setIsShowPasswordToggle = () => setIsShowPassword((prev) => !prev)

    const handleLogin = async (e: any) => {
        e.preventDefault()
        notificationSetter(null)
        const data = new FormData(e.target)
        const preparedData = {
            email: encodeURIComponent(data.get('email_SCAP') as string),
            password: encodeURIComponent(data.get('password_SCAP') as string),
        }
        const formBody = (Object.keys(preparedData) as ('email' | 'password')[])
            .map((key) => key + '=' + preparedData[key])
            .join('&')

        try {
            toggleIsLoading()
            const user = await fetcher<User>('/auth', {
                method: 'POST',
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                credentials: 'include',
                body: formBody,
            })
            userSetter(user)
            notificationSetter(null)
        } catch (err: any) {
            if (err instanceof Response) {
                const message = await responseToNotificationMessage(err)
                notificationSetter({
                    type: NotificationTypes.ERROR,
                    message: message,
                })
                setIsError(true)
            } else {
                notificationSetter({
                    type: NotificationTypes.ERROR,
                    message: `Something went wrong`,
                })
            }
        } finally {
            toggleIsLoading()
        }
    }

    return {
        handleLogin,
        isLoading,
        isError,
        setIsShowPasswordToggle,
        isShowPassword,
    }
}
