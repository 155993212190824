/** @jsxImportSource @emotion/react */
import React from 'react'
import { Typography } from '@mui/material'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import useStyles from './useStyles'
import { DEFAULT_ELEMENTS_PER_PAGE_RESULTS, IBlockData } from './Results'
import { IResultData } from '../../../context/formResult/types'
import ResultBlockInfoRow from './ResultBlockInfoRow'

const ResultInfoBlock = ({ data, title, maxElementsPerPage }: IBlockData) => {
    const classes = useStyles()
    const carouselPages: IResultData[][] = React.useMemo(() => {
        const elementsPerPage = maxElementsPerPage || DEFAULT_ELEMENTS_PER_PAGE_RESULTS
        const iterations = Math.ceil(data.length / elementsPerPage)

        return new Array(iterations).fill(null).map((_e, index) => {
            const [minIndex, maxIndex] = [index * elementsPerPage, index * elementsPerPage + elementsPerPage]
            const result = []

            for (let i = minIndex; i < maxIndex; i++) {
                result.push(data[i] || { label: null, value: null })
            }

            return result
        })
    }, [data, maxElementsPerPage])

    return (
        <div css={classes.resultBlockWrapper}>
            <Typography css={classes.resultBlockTitle}>{title}</Typography>
            <Carousel showArrows={false} showStatus={false} showThumbs={false} emulateTouch={true}>
                {carouselPages.map((el, index) => {
                    return (
                        <div css={classes.carouselPage} key={index}>
                            {el.map(({ label, value }, index) => (
                                <ResultBlockInfoRow key={`${label}_${title}_${index}`} title={label} value={value} />
                            ))}
                        </div>
                    )
                })}
            </Carousel>
        </div>
    )
}

export default React.memo(ResultInfoBlock)
