import { useTheme } from '@mui/material'
import { css } from '@emotion/react'
import { useMemo } from 'react'

export default () => {
    const theme = useTheme()

    return useMemo(
        () => ({
            airportsAutocomplete: css`
                margin-top: ${theme.spacing(2)};

                & .MuiSvgIcon-root {
                    color: ${theme.palette.primary.main};
                }
            `,
            runwayFormControl: css`
                margin-top: ${theme.spacing(3)};
            `,
            runwaySelectLabel: css`
                top: -5px;
                color: ${theme.palette.info.main};
                font-size: 16px;
                line-height: 19px;
            `,
            runwaySelectLabelEmpty: css`
                color: ${theme.palette.error.light};
            `,
            airportsAutocompleteTextField: css`
                & .MuiInputBase-root {
                    border-radius: 4px;
                    position: relative;
                    background-color: inherit;
                    padding: 2.5px 14px !important;
                    box-shadow: 0 2px 2px 0 #0000001a;
                    display: flex;
                    align-items: center;
                    color: ${theme.palette.info.main};

                    & .MuiAutocomplete-input {
                        padding-left: 2px;
                    }

                    &.Mui-error {
                        border: 1px solid ${theme.palette.error.main};
                    }
                }

                & .MuiButtonBase-root {
                    margin-right: -10px !important;
                }

                & .MuiInputLabel-root {
                    color: ${theme.palette.info.main};
                    font-size: 16px;
                    line-height: 19px;
                    top: -5px;
                }

                & fieldset {
                    border: none;
                }
            `,
            airportsAutocompleteTextFieldEmpty: css`
                & .MuiInputLabel-root {
                    color: ${theme.palette.error.light};
                }
            `,
            option: css`
                font-family: 'Roboto Mono', monospace;
            `,
            optionIcao: css`
                margin-right: 4px;
            `,
        }),
        [theme],
    )
}
